import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './unlockPackagePage';

class UnlockPackagePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.view = DOM;
    }

    render() {
        return this.view();
    }
}


UnlockPackagePage.propTypes = {
    match: PropTypes.object,
};

UnlockPackagePage.defaultProps = {
    match: {},
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UnlockPackagePage);
