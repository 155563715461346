/* global window:true */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native

/**
* Reducer
*/
import reducer from './reducers';

/**
* Sagas
*/
import rootSagas from './sagas';

/**
 * ## configureStore
 * @param {Object} the state
 */
const persistConfig = {
    key: 'engage-lime',
    storage,
    blacklist: ['global'],
    // transforms: [immutableTransform()],
};
const persistedReducer = persistReducer(persistConfig, reducer);

export default function configureStore(initialState) {
    const sagaMiddleware = createSagaMiddleware();

    const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;

    const store = createStore(
        persistedReducer,
        // reducer,
        initialState,
        composeEnhancers(applyMiddleware(
            sagaMiddleware,
            thunk,
        )),
    );
    const persistor = persistStore(store);

    sagaMiddleware.run(rootSagas);

    return { store, persistor };
}
