/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DOM from './header';
import * as userActions from '../../core/user/userActions';


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerVisible: false,
        };
        this.view = DOM;
    }

    onDrawerClose = () => {
        this.setState({ drawerVisible: false });
    }

    doLogoutRequest = () => {
        const { actions } = this.props;
        actions.logoutRequest();
    }

    render() {
        return this.view();
    }
}


function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.comic.isFetching,
        loginUser: state.user.loginUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

Header.propTypes = {
    actions: PropTypes.object,
    showMenu: PropTypes.bool,
};

Header.defaultProps = {
    actions: {},
    showMenu: true,
};


export default connect(mapStateToProps, mapDispatchToProps)(Header);
