import { all } from 'redux-saga/effects';
import gallerySaga from './gallery/gallerySaga';
import userSaga from './user/userSaga';
import comicSaga from './comic/comicSaga';
import fileSaga from './file/fileSaga';
import tagSaga from './tag/tagSaga';
import packageSaga from './package/packageSaga';
import companySaga from './company/companySaga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        gallerySaga(),
        userSaga(),
        comicSaga(),
        fileSaga(),
        tagSaga(),
        packageSaga(),
        companySaga(),
    ]);
}
