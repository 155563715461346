import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';
import DOM from './loginForm';
import * as userActions from '../../core/user/userActions';

class UserLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalForgetVisible: false,
        };
        this.view = DOM;
    }

    setModalForgetVisible(modalForgetVisible) {
        this.setState({ modalForgetVisible });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { form, actions } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                actions.loginRequest({
                    username: values.email,
                    password: values.password,
                });
            }
        });
    };

    handleForgotsubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err) => {
            if (!err) {
                // console.log('Received values of forgot form: ', values);
            }
        });
    };

    render() {
        return this.view();
    }
}

UserLoginForm.defaultProps = {
    actions: {},
};

UserLoginForm.propTypes = {
    form: PropTypes.object.isRequired,
    actions: PropTypes.object,
};

const LoginForm = Form.create()(UserLoginForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.user.isFetching,
        formStep: state.user.formStep,
        loginSteps: state.user.loginSteps,
        loginUser: state.user.loginUser,
        isLoggedIn: state.user.isLoggedIn,
        error: state.user.error,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
