import React from 'react';
import { Link } from 'react-router-dom';
import {
    Col, Spin, Row,
} from 'antd';
import _ from 'underscore';

import './resourceListComponent.scss';

export default function () {
    const { animations, isFetching } = this.props;
    const availableComicList = _.map(animations, (animation, animationKey) => {
        // eslint-disable-next-line
        const link = animation.comic_type === 2 // Magazine type
            ? `/account/magazine/${animation.slug}/${animation.pages[0].slug}`
            : `/account/animation/${animation.slug}/${animation.pages[0].slug}`;
        return (
            <Col key={animationKey} xs={24} sm={24} md={12}>
                <Link
                    className="resource-box"
                    to={link}>
                    <div className="image">
                        <img src={animation.imageUrl} alt={animation.name} />
                    </div>
                    <div className="title">
                        {animation.name}
                    </div>
                </Link>
            </Col>
        );
        // eslint-disable-next-line
    });

    return (
        <div className="resourceList-container">
            <div className="display-area">
                <Row style={{ margin: '30px auto' }}>
                    <Col xs={24}><h1>Pick a resource</h1></Col>
                </Row>
                <Spin spinning={isFetching}>
                    <Row gutter={24}>
                        {availableComicList}
                    </Row>
                </Spin>
            </div>
        </div>
    );
}
