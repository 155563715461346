import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    POST_ALLOCATE_CREDIT_REQUEST: null,
    POST_ALLOCATE_CREDIT_SUCCESS: null,
    POST_ALLOCATE_CREDIT_FAILURE: null,
});

export const {
    postAllocateCreditRequest,
    postAllocateCreditSuccess,
    postAllocateCreditFailure,
} = createActions(
    constants.POST_ALLOCATE_CREDIT_REQUEST,
    constants.POST_ALLOCATE_CREDIT_SUCCESS,
    constants.POST_ALLOCATE_CREDIT_FAILURE,
);
