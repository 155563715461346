/* global document:true */
import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Spin } from 'antd';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
// import AdminLayout from './components/AdminLayout';
// import EmptyLayout from './components/EmptyLayout';
// import Dashboard from './pages/Dashboard';
// import About from './pages/About';
// import Contact from './pages/Contact';
// import Index from './pages/NotFound';
import Routes from './core/utils/routes';
import history from './core/utils/history';


import './styles/style.css';

/**
 * ## Actions
 *  The necessary actions for dispatching our bootstrap values
 */
import { setVersion } from './core/global/globalActions';

/**
 *  The version of the app but not displayed yet
 */
import pack from '../package.json';

/**
 * ### configureStore
 */
import configureStore from './core/store';

const globalStore = configureStore({});

const { store, persistor } = globalStore;

const VERSION = pack.version;

const bugsnagClient = bugsnag('ef4a11823e1c1686b3a7d4e06d9cba02');
bugsnagClient.use(bugsnagReact, React);

// wrap your entire app tree in the ErrorBoundary provided
const ErrorBoundary = bugsnagClient.getPlugin('react');

store.dispatch(setVersion(VERSION));

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <PersistGate loading={<Spin />} persistor={persistor}>
                <Router history={history} basename="/">
                    <Routes persistor={persistor} />
                </Router>
            </PersistGate>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root'),
);
