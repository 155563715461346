import React from 'react';
import Helmet from 'react-helmet';

import Header from '../../components/Header';
import UnlockPackageForm from '../../components/UnlockPackageForm';
import './unlockPackagePage.scss';

export default function () {
    return (
        <div className="unlockPackagePage-wrapper">
            <Helmet title="Packages Page" />
            <Header showMenu={false} />
            <div className="unlockPackagePage-container">
                <UnlockPackageForm />
            </div>
        </div>
    );
}
