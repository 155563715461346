/* eslint-disable */
import React, { Component } from 'react';
import {
    Form, Button,
    Row, Col
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userActions from '../../../core/user/userActions';
import history from '../../../core/utils/history';

class PendingVerification extends Component {
    resendVerificationEmail = () => {
        const { actions, loginSteps } = this.props;
        actions.resendVerificationEmailRequest({ email: loginSteps.email });
    }

    goBack = (e) => {
        const { actions } = this.props;
        actions.flushLoginProcess();
        history.push('/');
    }

    render() {
        const { loginSteps } = this.props;
        return (
            <div className="login-form-box">
                <Form onSubmit={this.emailHandleSubmit} layout="vertical">
                    <Row>
                        <Col xs={24}>
                            <div className="header">Your email is pending verification</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <p>We've sent a welcome email to {loginSteps.email} containing a verification link to the email address you've provided</p>
                            <p>Please click the link in the email to verify your account and log in.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <a onClick={this.goBack}>Go back</a>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} style={{ margin: '10px auto' }}>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <p>Not received the email or has the verification link expired?</p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={24}>
                            <Button type="primary" htmlType="submit" className="wide" onClick={this.resendVerificationEmail}>Re-send verification email</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        loginSteps: state.user.loginSteps
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingVerification);
