/* global window */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { message } from 'antd';

import DOM from './fileStoragePage';
import * as fileActions from '../../core/file/fileActions';
import * as tagActions from '../../core/tag/tagActions';

class FileStoragePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTags: [],
            page: 1,
        };
        this.view = DOM;
    }

    componentWillMount() {
        const { actions } = this.props;
        this.requestFiles();
        actions.getListTagRequest();
    }

    requestFiles = () => {
        const { actions } = this.props;
        const { selectedTags, page } = this.state;

        actions.getAllFilesRequest({ tags: selectedTags, page });
    }

    tagsChanged = (value) => {
        this.setState({ selectedTags: value });
    }

    clearTags = () => {
        this.setState({ selectedTags: [] });
    }

    handlePageChange = (page) => {
        this.setState({ page }, this.requestFiles);
    }

    handleFileClick = (file) => {
        const { loginUser } = this.props;
        const { link, is_locked } = file;

        if (is_locked !== 1 || loginUser.role === 'admin') {
            window.open(link, '_blank');
        } else {
            message.warning('This file has been locked by an Engage Lime Administrator');
        }
    }

    render() {
        return this.view();
    }
}

FileStoragePage.propTypes = {
    actions: PropTypes.object.isRequired,
    loginUser: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        files: state.file.files,
        fetching: state.file.isFetching,
        tags: state.tag.tags,
        loginUser: state.user.loginUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...fileActions,
            ...tagActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FileStoragePage);
