import {
    call,
    put,
    takeEvery,
    all,
} from 'redux-saga/effects';
import { fetchApiAuth } from '../utils/api';


import {
    getAllAnimationsSuccess,
    getAllAnimationsFailure,

    getAnimationsSuccess,
    getAnimationsFailure,

    getAllMagazinesSuccess,
    getAllMagazinesFailure,

    markSessionAsDoneSuccess,
    markSessionAsDoneFailure,

} from './comicActions';

const {
    GET_ALL_ANIMATIONS_REQUEST,
    GET_ANIMATIONS_REQUEST,
    GET_ALL_MAGAZINES_REQUEST,
    MARK_SESSION_AS_DONE_REQUEST,
} = require('./comicActions').constants;

function* getAllAnimations({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'comic/list-animations',
            params: payload,
        });
        // eslint-disable-next-line
        if (response && response.data && response.data.success && response.data.success == true) {
            yield put(getAllAnimationsSuccess(response));
        } else {
            yield put(getAllAnimationsFailure(response && response.data && response.data.data ? response.data.data.message : response));
        }
    } catch (e) {
        yield put(getAllAnimationsFailure(e.response ? e.response.data.message : e));
    }
}

function* getAnimation(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `comic/view-resource/${action.payload.resource_slug}`,
        });
        // eslint-disable-next-line
        if (response && response.data && response.data.success && response.data.success == true) {
            yield put(getAnimationsSuccess(response));
        } else {
            yield put(getAnimationsFailure(response && response.data && response.data.data ? response.data.data.message : response));
        }
    } catch (e) {
        yield put(getAnimationsFailure(e.response ? e.response.data.message : e));
    }
}

function* getAllMagazines() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'comic/list-magazines',
        });

        yield put(getAllMagazinesSuccess(response));
    } catch (e) {
        yield put(getAllMagazinesFailure(e.response ? e.response.data.message : e));
    }
}

function* markSessionAsDone(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'comic/complete-session',
            body: action.payload,
        });

        yield put(markSessionAsDoneSuccess(response));
    } catch (e) {
        yield put(markSessionAsDoneFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* comicSaga() {
    yield all([
        takeEvery(GET_ALL_ANIMATIONS_REQUEST, getAllAnimations),
        takeEvery(GET_ANIMATIONS_REQUEST, getAnimation),
        takeEvery(GET_ALL_MAGAZINES_REQUEST, getAllMagazines),
        takeEvery(MARK_SESSION_AS_DONE_REQUEST, markSessionAsDone),
    ]);
}
