import {
    call,
    put,
    takeEvery,
    all,
} from 'redux-saga/effects';
import { fetchApiAuth } from '../utils/api';


import {
    getAllFilesSuccess,
    getAllFilesFailure,
} from './fileActions';

const {
    GET_ALL_FILES_REQUEST,
} = require('./fileActions').constants;

function* getAllFiles({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'media/all-media',
            params: payload,
        });
        if (response && response.data && response.data.success) {
            yield put(getAllFilesSuccess(response.data));
        } else {
            yield put(getAllFilesFailure(
                response && response.data && response.data.data
                    ? response.data.data.message
                    : response,
            ));
        }
    } catch (e) {
        yield put(getAllFilesFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* fileSaga() {
    yield all([
        takeEvery(GET_ALL_FILES_REQUEST, getAllFiles),
    ]);
}
