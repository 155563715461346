/* eslint-disable */
import React, { Component } from 'react';
import {
    Form, Input, Button, Modal,
    Row, Col
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userActions from '../../../core/user/userActions';

const FormItem = Form.Item;

class PasswordFormBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showResetModal: false
        };
    }

    componentWillMount() {

    }

    goBack = (e) => {
        const { actions } = this.props;
        actions.flushLoginProcess();
    }

    handleLoginSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions, loginSteps } = this.props;
                actions.loginRequest({
                    username: loginSteps.email,
                    password: values.password
                });
            }
        });
    }

    sendAutoLoginLink = () => {
        const { actions, loginSteps } = this.props;
        actions.sendAutoLoginRequest({
            email: loginSteps.email
        });
    }

    resetPasswordLink = () => {
        const { actions, loginSteps } = this.props;
        actions.forgotPasswordRequest({
            email: loginSteps.email
        });
        this.setState({ showResetModal: true });
    }

    resetPasswordModalOk = () => {
        this.setState({ showResetModal: false });
    }

    resetPasswordModalCancel = () => {
        this.setState({ showResetModal: false });
    }

    render() {
        const { form, loginSteps} = this.props;
        const { getFieldDecorator } = form;
        return (
            <div>
                <div className="login-form-box">
                    <Row>
                        <Col xs={24}>
                            <div className="header">Password</div>
                        </Col>
                    </Row>
                    <Form onSubmit={this.handleLoginSubmit} layout="vertical">
                        <Row>
                            <Col xs={24}>
                                <FormItem label="Your password">
                                    {getFieldDecorator('password', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input your password!'
                                            }
                                        ]
                                    })(<Input type="password" />)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '48px' }}>
                            <Col xs={24}>
                                <Button type="primary" htmlType="submit" className="wide">Log In</Button>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                            <Col xs={24}>
                                {
                                    // eslint-disable-next-line
                                }
                                <a onClick={this.goBack}>Go back</a>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="login-form-box">
                    <Row>
                        <Col xs={24}>
                            <div className="header">Cant remember your password?</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Button type="primary" htmlType="button" className="wide" onClick={this.sendAutoLoginLink}>Send login link</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        <Col xs={24}>
                            <a onClick={this.resetPasswordLink}>Reset your password</a>
                        </Col>
                    </Row>
                </div>
                <Modal
                    title={null}
                    visible={this.state.showResetModal}
                    onOk={this.resetPasswordModalOk}
                    footer={null}
                    onCancel={this.resetPasswordModalCancel}>
                    <Row>
                        <Col xs={24} style={{ textAlign: 'center' }}>
                            <p>We've sent a secure login link to your email:</p>
                            <p><strong>{loginSteps.email}</strong></p>
                            <p>Please click the link in this email to be automatically logged into the Lime portal. This link will expire in 48 hours.</p>
                            <p>Don't see this email? Refresh your inbox or check your spam/junk folders.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} style={{ textAlign: 'center' }}>
                            <Button type="primary" htmlType="button" className="wide" onClick={this.goBack}>Go back to login screen</Button>
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}

const PasswordForm = Form.create()(PasswordFormBox);


function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        loginSteps: state.user.loginSteps
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions
        }, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm);
