/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import Helmet from 'react-helmet';
import Script from 'react-load-script';
import {
    Row, Col, Spin, Icon,
} from 'antd';
import {
    isNull,
} from 'underscore';
import AdobeAnimate from '../../components/AdobeAnimate';
import './resourcePage.scss';

const {
    API_URL,
} = require('../../core/constants').default;

export default function () {
    const {
        selectedComic, selectedPage, scriptLoaded, playStatus,
    } = this.state;
    const {
        isComicFullscreen,
    } = this.props;

    const comicId = selectedComic && selectedComic.id;
    const animationName = selectedPage && selectedPage.animation && selectedPage.animation.name;
    const pageId = selectedPage && selectedPage.id;
    return (
        <div className="resource-page-wrap">
            <Spin spinning={false}>
                {!isNull(comicId) && !isNull(pageId) && !isNull(animationName) && scriptLoaded === false
                    ? (
                        <Script
                            attributes={{ class: 'injected-script' }}
                            url={`${(API_URL).replace('/api/', '/')}files/comics/${selectedComic.id}/${selectedPage.id}/${selectedPage.animation.name}/${selectedPage.animation.name}.js`}
                            onCreate={this.handleScriptCreateSingle}
                            onError={this.handleScriptErrorSingle}
                            onLoad={this.handleScriptLoadSingle} />
                    )
                    : null
                }
                <Helmet title="Resource Page" />
                {/* <Header refreshButton={refreshButton} /> */}
                {isComicFullscreen
                    ? (
                        <div className="exit-fullscreen-button" onClick={this.exitFullScreen}>
                            <Icon type="fullscreen-exit" />
                            {' '}
                            Exit Fullscreen
                        </div>
                    )
                    : null
                }
                <div className="resource-page-container">
                    <div className="display-area" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                        <Row style={{ margin: '30px auto' }}>
                            <Col xs={24}><h1>{selectedComic && selectedComic.name ? selectedComic.name : null}</h1></Col>
                        </Row>
                        {scriptLoaded
                            ? (
                                <AdobeAnimate
                                    ref={this.adobeObject}
                                    id="animation-canvas"
                                    paused={playStatus}
                                    getAnimationObject={this.getAnimationObject}
                                    style={{ border: '5px solid #000' }}
                                    animationName={selectedPage.animation.name} />
                            )
                            : null
                        }
                    </div>
                </div>
            </Spin>
        </div>
    );
}
