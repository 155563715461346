import {
    call, put, takeEvery, all,
} from 'redux-saga/effects';
import { message } from 'antd';
import alertify from 'alertify.js';

import { fetchApiAuth } from '../utils/api';

import {
    constants,
    postAllocateCreditSuccess,
    postAllocateCreditFailure,
} from './companyActions';

const { POST_ALLOCATE_CREDIT_REQUEST } = constants;

function* postAllocateCredit({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/company/allocate',
            body: payload,
        });

        if (response && response.data && response.data.success && response.data.success === true) {
            alertify.success('Resource successfully unlocked');
            yield put(postAllocateCreditSuccess(response));
        } else {
            yield put(postAllocateCreditFailure(response && response.data && response.data.data ? response.data.data.message : response));
        }

        yield put(postAllocateCreditSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(postAllocateCreditFailure(e.response ? e.response.data.message : e));
    }
}

export default function* objectSaga() {
    yield all([takeEvery(POST_ALLOCATE_CREDIT_REQUEST, postAllocateCredit)]);
}
