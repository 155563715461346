import { Record } from 'immutable';

const {
    GET_LIST_TAG_REQUEST,
    GET_LIST_TAG_SUCCESS,
    GET_LIST_TAG_FAILURE,
} = require('./tagActions').constants;

const InitialState = Record({
    tags: [],
    isFetching: false,
    error: null,
});

const initialState = new InitialState();

/**
* ## galleryReducer function
* @param {Object} state - initialState
* @param {Object} action - type and payload
*/
export default function tagReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_LIST_TAG_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);
    case GET_LIST_TAG_SUCCESS:
        return state.set('isFetching', false)
            .set('tags', payload.data.data);
    case GET_LIST_TAG_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);
    default:
        return state;
    }
}
