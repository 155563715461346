import React from 'react';
import PropTypes from 'prop-types';
import {
    CloseCircleOutlined,
    CheckCircleOutlined,
    QuestionCircleOutlined,
    ClockCircleOutlined,
} from '@ant-design/icons';
import history from '../../../core/utils/history';

import './SessionsList.scss';

export default function SessionsList({ data, magazineSlug }) {
    const header = (
        <div className="session-list-row session-list-row-header">
            <div className="session-list-col session-list-col-name">
                Session
            </div>
            <div className="session-list-col session-list-col-status">
                Status
            </div>
            <div className="session-list-col session-list-col-availability">
                Availability
            </div>
            <div className="session-list-col session-list-col-actions" />
        </div>
    );

    const showSession = (slug) => {
        history.push(`/account/magazine/${magazineSlug}/${slug}`);
    };

    const sessions = data.map((session) => {
        let status = (
            <span style={{ color: 'red' }}>
                <CloseCircleOutlined style={{ fontSize: 16 }} /> Not available
            </span>
        );
        if (session.availability === true) {
            if (session.completed) {
                status = (
                    <span style={{ color: 'green' }}>
                        <CheckCircleOutlined style={{ fontSize: 16 }} /> Done
                    </span>
                );
            } else {
                status = (
                    <span>
                        <QuestionCircleOutlined style={{ fontSize: 16 }} /> Pending
                    </span>
                );
            }
        } else if (session.availability !== false) {
            status = (
                <span style={{ color: 'orange' }}>
                    <ClockCircleOutlined style={{ fontSize: 16 }} /> Coming soon
                </span>
            );
        }
        return (
            <div className="session-list-row">
                <div className="session-list-col session-list-col-name">
                    {session.name}
                </div>
                <div className="session-list-col session-list-col-status">
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {status}
                </div>
                <div className="session-list-col session-list-col-availability">
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {session.availability === true
                        ? 'Available'
                        : session.availability === false
                            ? ''
                            : `Due ${session.availability}`
                    }
                </div>
                <div className="session-list-col session-list-col-actions">
                    {session.availability === true
                        ? (
                            <span
                                className="page-link"
                                onClick={() => showSession(session.slug)}>
                                View
                            </span>
                        )
                        : null}
                </div>
            </div>
        );
    });


    return (
        <div className="sessions-list-feature-wrapper">
            <h2>Sessions</h2>
            <div className="sessions-list-wrapper">
                {header}
                {sessions}
            </div>
        </div>
    );
}

SessionsList.defaultProps = {
    data: [],
    magazineSlug: '',
};

SessionsList.propTypes = {
    data: PropTypes.array,
    magazineSlug: PropTypes.string,
};
