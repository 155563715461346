import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './magazinePage';
import * as comicActions from '../../core/comic/comicActions';

class MagazinePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.view = DOM;
    }

    markDone = (sessionId) => {
        const { actions } = this.props;
        actions.markSessionAsDoneRequest({ session_id: sessionId });
    }

    render() {
        const { magazines, match } = this.props;
        const { params } = match;
        const magazine = magazines.find(e => e.slug === params.comic);
        const session = magazine.sessions.find(e => e.slug === params.page);
        return this.view(magazine, session);
    }
}

MagazinePage.defaultProps = {
    magazines: [],
};

MagazinePage.propTypes = {
    actions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    magazines: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        magazines: state.comic.magazines,
        fetching: state.comic.isMagazinesFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...comicActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MagazinePage);
