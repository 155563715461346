import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import {
    Row, Col, Button, Spin, Icon,
} from 'antd';
import {
    map, filter, each, isEmpty,
} from 'underscore';
import ReactPlayer from 'react-player';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import Header from '../../components/Header';
import './resourcePageCache.scss';

export default function () {
    const { match, loginUser } = this.props;
    const externalUser = loginUser.role === 'external';
    const { params } = match;
    const {
        selectedComic, selectedPage, initialPage, vimeoPlayer, selectedVideoUrl, videoEnded, playing,
    } = this.state;
    const filteredComics = selectedComic ? filter(selectedComic.pages, page => page.slug === selectedPage.slug) : [];

    const refreshButton = (
        <Link to={`/account/animation/${selectedComic ? selectedComic.slug : null}/${initialPage}`}>
            <div className="reload-icon" />
        </Link>
    );

    const replayButton = (
        <div onClick={() => { this.replayVideo(); }} className="replay-icon" />
    );

    const navigationButtonBack = <Icon id="navigation-back-button" className="resource-page-cache-hide" style={{ fontSize: '28px', color: '#FFFFFF', paddingTop: '10px' }} type="left-circle" onClick={() => this.moveNavigationState('back')} />;
    const navigationButtonForward = <Icon id="navigation-forward-button" className="resource-page-cache-hide" style={{ fontSize: '28px', color: '#FFFFFF', paddingTop: '10px' }} type="right-circle" onClick={() => this.moveNavigationState('forward')} />;
    return (
        <div className="resource-page-wrap">
            <Spin size="large" spinning={this.Loading} tip="Animation will start once loading is complete.">
                <Helmet title="Resource Page" />
                <Header
                    replayButton={replayButton}
                    refreshButton={refreshButton}
                    back={vimeoPlayer ? undefined : navigationButtonBack}
                    forward={vimeoPlayer ? undefined : navigationButtonForward}
                    showMenu={!externalUser} />
                <div className="exit-fullscreen-button" style={{ display: 'none' }} onClick={this.exitFullScreen}>
                    <Icon type="fullscreen-exit" />
                    {' '}
                    Exit Fullscreen
                </div>
                <Row id="iframe-window-container" gutter={24} style={{ height: '80vh', zIndex: 9, width: '100%' }}>
                    {/* Vimeo */}
                    {vimeoPlayer && (
                        <div className="vimeoContainer">
                            <h1 style={{ marginTop: 30 }}>{selectedComic.name}</h1>
                            <div className="videoContainer">
                                <Carousel
                                    className="carousel-wrapper"
                                    showIndicators={false}
                                    showArrows={videoEnded}
                                    renderItem={this.renderVideo}
                                    onChange={this.onVideoStart}
                                    showThumbs={false}>
                                    {map(selectedComic.pages, page => (
                                        <ReactPlayer
                                            key={`vimeo-video-${page.id}`}
                                            className="react-player"
                                            url={page.vimeo_url_link}
                                            width="100%"
                                            height="100%"
                                            playing={page.vimeo_url_link === selectedVideoUrl && playing}
                                            muted
                                            onEnded={() => { this.onVideoEnd(page.id); }}
                                            onPlay={this.onVideoStart} />
                                    )) }
                                </Carousel>
                            </div>
                        </div>
                    )}
                    { !vimeoPlayer && map(filteredComics, (page, pageIndex) => {
                        // Animation
                        let className = 'current-iframe';
                        let paused = 'play';
                        if (pageIndex > 0) {
                            paused = 'play';
                            className = null;
                        }
                        let src = `/account/comics/${selectedComic.slug}/${page.slug}/${paused}`;
                        // eslint-disable-next-line
                        if (params.fullScreen && params.fullScreen == 'fullscreen') {
                            src = `/account/comics/${selectedComic.slug}/${page.slug}/${paused}/fullScreen`;
                        }
                        const dataset = [];
                        dataset['data-comic'] = selectedComic.id;
                        if (page && page.animation && page.animation.actions) {
                            each(page.animation.actions, (action) => {
                                dataset[`data-${(action.label).toLowerCase()}`] = action.mapped_page_id;
                            });
                            return (
                                <iframe
                                    key={pageIndex}
                                    title={`Title-${pageIndex}`}
                                    {...dataset}
                                    id={`iframe-page-${page.id}`}
                                    height="100%"
                                    width="100%"
                                    className={className}
                                    frameBorder={0}
                                    src={src} />
                            );
                        }
                        return false;
                    })}
                </Row>
                <div className="resource-page-container" style={{ zIndex: 1 }}>
                    <div className="display-area" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                        {!vimeoPlayer && (
                            <Row style={{ marginTop: '20px' }}>
                                <Col xs={24}>
                                    <Button type="primary" htmlType="button" className="wide" onClick={this.goToFullScreen}>
                                        <div className="fullscreen-icon" style={{ marginRight: '10px' }} />
                                        Fullscreen
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </div>
                </div>
            </Spin>
        </div>
    );
}
