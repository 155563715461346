import React from 'react';
import Helmet from 'react-helmet';
import LoginForm from '../../components/LoginForm';

export default function () {
    // const script = document.createElement('script');
    // script.src = 'http://localhost/animate/stick.js';
    // document.body.appendChild(script);

    return (
        <div className="page-wrap login">
            <Helmet title="Login" />
            <LoginForm params={this.props.match.params} />
        </div>
    );
}
