/* eslint-disable react/no-this-in-sfc */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Helmet from 'react-helmet';
import {
    LeftOutlined,
    CheckOutlined,
} from '@ant-design/icons';
import {
    Row,
    Col,
    Spin,
    Button,
} from 'antd';
import './magazinePage.scss';
import history from '../../core/utils/history';
import FormattedText from './partials/FormattedText';
import Materials from './partials/Materials';
import SessionsList from './partials/SessionsList';

export default function (magazine, rawSession) {
    const { fetching } = this.props;
    let session = null;

    if (rawSession === undefined) {
        // create default session
        session = {
            id: 0,
            name: 'Unknown session',
            above_text: null,
            below_text: null,
            embed_code: null,
            next: null,
            previous: null,
            materials: [],
        };
    } else {
        session = rawSession;
    }

    let sessionNumber = '';
    if (magazine.sessions.length > 1) {
        const currentPage = session !== undefined
            ? magazine.sessions.map(s => s.id).indexOf(session.id) + 1
            : null;
        sessionNumber = ` (${currentPage}/${magazine.sessions.length})`;
    }

    let content = null;

    if (rawSession === undefined) {
        content = (
            <>
                <div className="background-image-wrapper">
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    {magazine.backgroundUrl && magazine.backgroundUrl !== '' ? <img src={magazine.backgroundUrl} alt="" /> : null}
                </div>
                <div className="file-storage-page-container">
                    <div className="display-area">
                        <Row style={{ margin: '30px auto' }}>

                            <Col xs={24}><h2 style={{ marginTop: 50 }}>Could not find session</h2></Col>

                            <Col xs={24}><SessionsList data={magazine.sessions} magazineSlug={magazine.slug} /></Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    } else if (!session.available) {
        content = (
            <>
                <div className="background-image-wrapper">
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    {magazine.backgroundUrl && magazine.backgroundUrl !== '' ? <img src={magazine.backgroundUrl} alt="" /> : null}
                </div>
                <div className="file-storage-page-container">
                    <div className="display-area">
                        <Row style={{ margin: '30px auto' }}>

                            <Col xs={24}><h2 style={{ marginTop: 50 }}>This session is not current available</h2></Col>

                            <Col xs={24}><SessionsList data={magazine.sessions} magazineSlug={magazine.slug} /></Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    } else {
        content = (
            <>
                <div className="background-image-wrapper">
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    {magazine.backgroundUrl && magazine.backgroundUrl !== '' ? <img src={magazine.backgroundUrl} alt="" /> : null}
                </div>
                <div className="file-storage-page-container">
                    <div className="display-area">
                        <Row style={{ margin: '30px auto' }}>

                            <Col xs={24}><FormattedText>{session.above_text}</FormattedText></Col>
                            {/* eslint-disable-next-line react/no-danger */}
                            <Col xs={24}><div dangerouslySetInnerHTML={{ __html: session.embed_code }} /></Col>
                            <Col xs={24}><FormattedText>{session.below_text}</FormattedText></Col>

                            <Col xs={24}><Materials data={session.materials} /></Col>

                            <Col xs={24}><SessionsList data={magazine.sessions} magazineSlug={magazine.slug} /></Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    }


    return (
        <div className="file-storage-page-wrap">
            <Spin spinning={fetching}>
                <Helmet title="Magazine Page" />
                <div className="top-headers">
                    <div className="top-bar">
                        <div className="resource-page-container">
                            <Col xs={20}>
                                <div className="title">
                                    {magazine.name}
                                </div>
                            </Col>
                        </div>
                    </div>
                    <div className="session-bar">
                        <div className="navigation-button-container" style={{ padding: '10px 0px' }}>
                            {!session.previous
                                ? null
                                : <span className="nav-button" onClick={() => history.push(`/account/magazine/${magazine.slug}/${session.previous}`)}>Previous</span>
                            }
                        </div>
                        <div className="header-container">
                            <div className="title">
                                <h1>{session.name}{sessionNumber}</h1>
                                {session.completed
                                    ? <span style={{ marginLeft: 6 }}><CheckOutlined /> Done</span>
                                    : <Button onClick={() => this.markDone(session.id)}>Mark as done</Button>
                                }
                            </div>
                        </div>
                        <div className="navigation-button-container">
                            {!session.next
                                ? <span className="nav-button not-yet">{session.nextAvailable ? `Next Due ${session.nextAvailable}` : 'Next session not availble'}</span>
                                : <span className="nav-button" onClick={() => history.push(`/account/magazine/${magazine.slug}/${session.next}`)}>Next</span>
                            }
                        </div>
                    </div>
                </div>
                {content}
            </Spin>
        </div>
    );
}
