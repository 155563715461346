import { Record } from 'immutable';
import { REHYDRATE } from 'redux-persist';

const {
    SET_FORM_STEP,
    FLUSH_LOGIN_PROCESS,

    DELETE_TOKEN_REQUEST,
    DELETE_TOKEN_SUCCESS,

    SAVE_TOKEN_REQUEST,
    SAVE_TOKEN_SUCCESS,

    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,

    VERIFY_SHARE_PACKAGE_REQUEST,
    VERIFY_SHARE_PACKAGE_SUCCESS,
    VERIFY_SHARE_PACKAGE_FAILURE,

    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,

    CHECK_USER_EMAIL_REQUEST,
    CHECK_USER_EMAIL_SUCCESS,
    CHECK_USER_EMAIL_FAILURE,

    RESEND_VERIFICATION_EMAIL_REQUEST,
    RESEND_VERIFICATION_EMAIL_SUCCESS,
    RESEND_VERIFICATION_EMAIL_FAILURE,

    DO_AUTO_LOGIN_REQUEST,
    DO_AUTO_LOGIN_SUCCESS,
    DO_AUTO_LOGIN_FAILURE,

    SEND_AUTO_LOGIN_REQUEST,
    SEND_AUTO_LOGIN_SUCCESS,
    SEND_AUTO_LOGIN_FAILURE,

    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,

    FIRST_TIME_LOGIN_REQUEST,
    FIRST_TIME_LOGIN_SUCCESS,
    FIRST_TIME_LOGIN_FAILURE,

    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,

} = require('./userActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isLoggedIn: false,
    loginUser: null,
    formStep: 0,
    loginSteps: {},
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case REHYDRATE:
        return initialState;
    case LOGIN_REQUEST:
    case VERIFY_SHARE_PACKAGE_REQUEST:
    case DELETE_TOKEN_REQUEST:
    case SAVE_TOKEN_REQUEST:
    case LOGOUT_REQUEST:
    case RESEND_VERIFICATION_EMAIL_REQUEST:
    case DO_AUTO_LOGIN_REQUEST:
    case SEND_AUTO_LOGIN_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
    case FIRST_TIME_LOGIN_REQUEST:
    case CHANGE_PASSWORD_REQUEST:
    case CHECK_USER_EMAIL_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case RESEND_VERIFICATION_EMAIL_SUCCESS:
    case SEND_AUTO_LOGIN_SUCCESS:
    case FORGOT_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
        return state.set('isFetching', false);
    case SAVE_TOKEN_SUCCESS:
    case LOGIN_SUCCESS:
    case DO_AUTO_LOGIN_SUCCESS: {
        const { loginSteps } = state;
        loginSteps.account = true;
        loginSteps.email_pending = false;
        return state.set('isFetching', false)
            .set('loginSteps', loginSteps)
            .set('loginUser', (payload.data ? payload.data : null))
            .set('isLoggedIn', true);
    }
    case VERIFY_SHARE_PACKAGE_SUCCESS:
        return state.set('isFetching', false).set('loginUser', payload);
    case SET_FORM_STEP:
        return state.set('formStep', payload);
    case DELETE_TOKEN_SUCCESS:
    case LOGOUT_SUCCESS:
        return state.set('isFetching', false)
            .set('loginSteps', null)
            .set('loginUser', null)
            .set('isLoggedIn', false);
    case CHECK_USER_EMAIL_SUCCESS:
    case FIRST_TIME_LOGIN_SUCCESS:
        return state.set('isFetching', false)
            .set('loginSteps', payload.data.data)
            .set('isLoggedIn', false);
    case FLUSH_LOGIN_PROCESS:
        return state.set('loginSteps', null)
            .set('loginUser', null);

    case LOGIN_FAILURE:
    case VERIFY_SHARE_PACKAGE_FAILURE:
    case LOGOUT_FAILURE:
    case CHECK_USER_EMAIL_FAILURE:
    case RESEND_VERIFICATION_EMAIL_FAILURE:
    case DO_AUTO_LOGIN_FAILURE:
    case SEND_AUTO_LOGIN_FAILURE:
    case FORGOT_PASSWORD_FAILURE:
    case FIRST_TIME_LOGIN_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
        return state.set('isFetching', false)
            .set('isLoggedIn', false)
            .set('error', payload);
    default:
        return state;
    }
}
