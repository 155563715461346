import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    REHYDRATION_COMPLETED: null,
    SET_VERSION: null,
    SET_BREADCRUMB: null,
    SET_FULLSCREEN: false,
});

export const {
    rehydrationCompleted,
    setVersion,
    setBreadcrumb,
    setFullscreen,
} = createActions(
    constants.REHYDRATION_COMPLETED,
    constants.SET_VERSION,
    constants.SET_BREADCRUMB,
    constants.SET_FULLSCREEN,
);
