/* global localStorage */
import {
    call, put, takeEvery, all,
} from 'redux-saga/effects';
import alertify from 'alertify.js';
import storage from 'redux-persist/lib/storage';
import lockr from 'lockr';
import { message } from 'antd';

import { fetchApi, fetchApiAuth } from '../utils/api';
import history from '../utils/history';
import {
    saveTokenSuccess,
    loginSuccess,
    loginFailure,
    verifySharePackageSuccess,
    verifySharePackageFailure,
    logoutSuccess,
    checkUserEmailSuccess,
    checkUserEmailFailure,
    resendVerificationEmailSuccess,
    resendVerificationEmailFailure,
    doAutoLoginSuccess,
    doAutoLoginFailure,
    sendAutoLoginSuccess,
    sendAutoLoginFailure,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    firstTimeLoginSuccess,
    firstTimeLoginFailure,
    changePasswordSuccess,
    changePasswordFailure,
} from './userActions';

alertify.logPosition('top right');

const {
    AUTH_KEY,
    AUTH_ROLE,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../constants').default;

const {
    LOGIN_REQUEST,
    VERIFY_SHARE_PACKAGE_REQUEST,
    LOGOUT_REQUEST,
    CHECK_USER_EMAIL_REQUEST,
    RESEND_VERIFICATION_EMAIL_REQUEST,
    DO_AUTO_LOGIN_REQUEST,
    SEND_AUTO_LOGIN_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    FIRST_TIME_LOGIN_REQUEST,
    CHANGE_PASSWORD_REQUEST,
} = require('./userActions').constants;

// function* deleteSessionToken() {
//     lockr.rm(AUTH_KEY);
//     lockr.rm(API_LOGIN_KEY);
//     yield put(deleteTokenSuccess());
// }

function* saveSessionToken(action) {
    // const authDate = new Date();
    lockr.set(LOCALSTORAGE_EXPIRES_KEY, action.data.expires);
    lockr.set(AUTH_KEY, action.data.auth_token);
    lockr.set(AUTH_ROLE, action.data.role);
    yield put(saveTokenSuccess(action));
}

function* makeLoginRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'login',
            body: { username: action.payload.username, password: action.payload.password },
        });
        // eslint-disable-next-line
        if (response && response.data && response.data.data.success && response.data.data.success == true) {
            yield call(saveSessionToken, response.data);
            yield put(loginSuccess(response.data));
            history.push('/login');
        } else {
            yield put(loginFailure(response && response.data && response.data.data ? response.data.data.message : response));
        }
    } catch (e) {
        yield put(loginFailure(e.response.data ? e.response.data.message : e));
    }
}

function* verifySharePackage({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: '/package/verify-share-package',
            params: payload,
        });
        yield put(verifySharePackageSuccess(response?.data || {}));

        const data = response?.data?.data || {};
        if (data.success && data.success === true) {
            lockr.set(LOCALSTORAGE_EXPIRES_KEY, data.expires);
            lockr.set(AUTH_KEY, data.auth_token);
            lockr.set(AUTH_ROLE, data.role);
            history.push(`/account/external/comics/${data.package_id}`);
            yield put(verifySharePackageSuccess(data));
        } else {
            yield put(verifySharePackageFailure(response && response.data && response.data.data ? response.data.data.message : response));
        }
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(verifySharePackageFailure(e.response ? e.response.data.message : e));
    }
}

function* makeAutoLoginRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'auto-login',
            body: { key: action.payload.key },
        });
        // eslint-disable-next-line
        if (response && response.data && response.data.success && response.data.success == true) {
            yield call(saveSessionToken, response.data);
            yield put(doAutoLoginSuccess(response.data));
            history.push('/account/packages');
        } else {
            yield put(doAutoLoginFailure(response && response.data && response.data.data ? response.data.data.message : response));
        }
    } catch (e) {
        yield put(doAutoLoginFailure((e.response && e.response.data) ? e.response.data.message : e));
    }
}

function* checkEmailRequest({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'check-email',
            body: payload,
        });
        // eslint-disable-next-line
        if (response.data && response.data.success && response.data.success == true) {
            yield put(checkUserEmailSuccess(response));
            history.push('/login');
        } else {
            yield put(checkUserEmailFailure(response.data && response.data.data ? response.data.data.message : response));
            history.push('/login/invalid');
        }
    } catch (e) {
        yield put(checkUserEmailFailure(e.response ? e.response.data.message : e));
        history.push('/login/invalid');
    }
}

function* resendVerifyEmailRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'resend-verify-email',
            body: { email: action.payload.email },
        });
        // eslint-disable-next-line
        if (response.data && response.data.success && response.data.success == true) {
            yield put(resendVerificationEmailSuccess(response));
            alertify.success('Verification email sent successfully.');
        } else {
            yield put(resendVerificationEmailFailure(response.data.data ? response.data.data.message : response));
        }
    } catch (e) {
        yield put(resendVerificationEmailFailure(e.response ? e.response.data.message : e));
    }
}

function* sendAutoLoginRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'request-auto-login',
            body: { email: action.payload.email },
        });
        // eslint-disable-next-line
        if (response.data && response.data.success && response.data.success == true) {
            yield put(sendAutoLoginSuccess(response));
            alertify.success('Auto login email sent successfully.');
        } else {
            yield put(sendAutoLoginFailure(response.data.data ? response.data.data.message : response));
        }
    } catch (e) {
        yield put(sendAutoLoginFailure(e.response ? e.response.data.message : e));
    }
}

function* forgotPasswordRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'forgot-password',
            body: { email: action.payload.email },
        });
        // eslint-disable-next-line
        if (response.data && response.data.success && response.data.success == true) {
            yield put(forgotPasswordSuccess(response));
            alertify.success('Please check your email and follow instructions.');
        } else {
            yield put(forgotPasswordFailure(response.data.data ? response.data.data.message : response));
        }
    } catch (e) {
        yield put(forgotPasswordFailure(e.response ? e.response.data.message : e));
    }
}

function* firstTimeLogin(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'first-login',
            body: action.payload,
        });
        // eslint-disable-next-line
        if (response.data && response.data.success && response.data.success == true) {
            yield put(firstTimeLoginSuccess(response));
        } else {
            yield put(firstTimeLoginFailure(response.data.data ? response.data.data.message : response));
        }
    } catch (e) {
        yield put(firstTimeLoginFailure(e.response ? e.response.data.message : e));
    }
}

function* changePassword(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'settings',
            body: action.payload,
        });
        // eslint-disable-next-line
        if (response.data && response.data.success && response.data.success == true) {
            yield put(changePasswordSuccess(response));
            alertify.success('Password changed successfully.');
            history.push('/account/packages');
        } else {
            yield put(changePasswordFailure(response.data.data ? response.data.data.message : response));
        }
    } catch (e) {
        yield put(changePasswordFailure(e.response ? e.response.data.message : e));
    }
}

function* logoutRequest() {
    yield put(logoutSuccess());
    localStorage.clear();
    storage.removeItem('persist:root');
    lockr.flush();
    history.push('/');
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(LOGIN_REQUEST, makeLoginRequest),
        takeEvery(VERIFY_SHARE_PACKAGE_REQUEST, verifySharePackage),
        takeEvery(LOGOUT_REQUEST, logoutRequest),
        takeEvery(CHECK_USER_EMAIL_REQUEST, checkEmailRequest),
        takeEvery(RESEND_VERIFICATION_EMAIL_REQUEST, resendVerifyEmailRequest),
        takeEvery(DO_AUTO_LOGIN_REQUEST, makeAutoLoginRequest),
        takeEvery(SEND_AUTO_LOGIN_REQUEST, sendAutoLoginRequest),
        takeEvery(FORGOT_PASSWORD_REQUEST, forgotPasswordRequest),
        takeEvery(FIRST_TIME_LOGIN_REQUEST, firstTimeLogin),
        takeEvery(CHANGE_PASSWORD_REQUEST, changePassword),
    ]);
}
