/* eslint-disable */
import React, { Component } from 'react';
import {
    Form, Input, Button,
    Row, Col
} from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userActions from '../../../core/user/userActions';

const FormItem = Form.Item;

class SetPasswordBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false
        };
    }

    setPasswordHandleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions, loginSteps } = this.props;
                actions.firstTimeLoginRequest({
                    email: loginSteps.email,
                    password: values.password
                });
            }
        });
    };

    continueWithoutSettingPassword = (e) => {
        e.preventDefault();
        const { actions, loginSteps } = this.props;
        actions.firstTimeLoginRequest({
            email: loginSteps.email
        });
    }

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <div className="login-form-box">
                <Form onSubmit={this.setPasswordHandleSubmit} layout="vertical">
                    <Row>
                        <Col xs={24}>
                            <div className="header">Please set your password</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <p>This is the first time you've logged into the Lime Portal. Please set your password:</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <FormItem label="New password">
                                {getFieldDecorator('password', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter a password'
                                        }
                                    ]
                                })(<Input type="password" />)}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '40px' }}>
                        <Col xs={24}>
                            <Button type="primary" htmlType="submit" className="wide">Set Password</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <Col xs={24}>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <p>Or, if you'd prefer to continue to use the password we set for you (which you can find in your welcome email) then you can skip this process</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <a onClick={this.continueWithoutSettingPassword}>Continue & don't set a new password</a>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

SetPasswordBox.propTypes = {
    form: PropTypes.object.isRequired
};


const SetPassword = Form.create()(SetPasswordBox);


function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        loginUser: state.user.loginUser,
        loginSteps: state.user.loginSteps
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
