import {
    call, put, takeEvery, all,
} from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    constants,
    getListTagSuccess,
    getListTagFailure,
} from './tagActions';

const { GET_LIST_TAG_REQUEST } = constants;

function* getListTag() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/tag/list',
        });

        if (response && response.data && response.data.success) {
            yield put(getListTagSuccess(response));
        } else {
            yield put(getListTagFailure(
                response && response.data && response.data.data
                    ? response.data.data.message
                    : response,
            ));
        }
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getListTagFailure(e.response ? e.response.data.message : e));
    }
}

export default function* objectSaga() {
    yield all([takeEvery(GET_LIST_TAG_REQUEST, getListTag)]);
}
