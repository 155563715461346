/* global navigator */
import React from 'react';
import Helmet from 'react-helmet';
import {
    Row, Col, Button, Modal, Input, Icon,
} from 'antd';
import { CopyOutlined, ShareAltOutlined } from '@ant-design/icons';
import _, { isEmpty } from 'underscore';
import Header from '../../components/Header';
import './listResourcePage.scss';
import history from '../../core/utils/history';
import ResourceListComponent from '../../components/ResourceListComponent';

const {
    APP_URL,
} = require('../../core/constants').default;

export default function () {
    const { sharePackage, isFetching, loginUser } = this.props;

    const showSharePackage = loginUser.role === 'admin' || loginUser.role === 'teacher';

    const navigationButtonBack = (
        <Icon
            id="navigation-back-button"
            className="resource-page-cache-hide"
            style={{ fontSize: '28px', color: '#FFFFFF', paddingTop: '10px' }}
            type="left-circle"
            onClick={() => { history.replace('/account/packages'); }} />
    );


    return (
        <div className="resource-page-wrap">
            <Helmet title="Resource Page" />
            <Header back={navigationButtonBack} />
            <ResourceListComponent
                askForComicLicenseKey={(comicId, packageId, companyPackageId, comicSlug, comicPageSlug, comicType) => this.askForComicLicenseKey(comicId, packageId, companyPackageId, comicSlug, comicPageSlug, comicType)}
                autoAssignComic={(comicId, packageId, companyPackageId, comicSlug, comicPageSlug, comicType) => this.autoAssignComic(comicId, packageId, companyPackageId, comicSlug, comicPageSlug, comicType)}
                notAllowedComic={() => this.notAllowedComic()} />
            {showSharePackage && (
                <Button
                    size="large"
                    disabled={isFetching}
                    style={{ backgroundColor: '#90ee90' }}
                    onClick={this.handleSharePackageClick}>
                    <ShareAltOutlined />
                    Share Package
                </Button>
            )}
            {!isEmpty(sharePackage) && showSharePackage && (
                <div className="sharePackageDetails">
                    <div className="sharePackageElement">
                        <Input addonBefore="URL" value={`${APP_URL}code`} />
                        <Button onClick={() => { navigator.clipboard.writeText(`${APP_URL}code`); }}>
                            <CopyOutlined />
                        </Button>
                    </div>
                    <div className="sharePackageElement">
                        <Input addonBefore="Access Code" value={sharePackage.access_code} />
                        <Button onClick={() => { navigator.clipboard.writeText(sharePackage.access_code); }}>
                            <CopyOutlined />
                        </Button>
                    </div>
                    <div>
                        Please provide the URL and access code to your students.
                    </div>
                </div>
            )}
            <Modal
                title="License Key Required"
                footer={null}
                visible={this.state.showLicenseKeyModal}
                // onOk={this.okLicenseKeyModal}
                onCancel={this.closeLicenseKeyModal}>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={24} style={{ textAlign: 'center' }}>
                        <Input
                            value={this.state.licenseCode}
                            onChange={e => this.setState({ licenseCode: e.target.value })}
                            className="custom-input"
                            placeholder="Enter License Key" />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} style={{ textAlign: 'center' }}>
                        <Button
                            disabled={_.isEmpty(this.state.licenseCode)}
                            onClick={this.okLicenseKeyModal}
                            type="primary"
                            htmlType="button"
                            className="wide">
                            Validate
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
}
