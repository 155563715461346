import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_LIST_TAG_REQUEST: null,
    GET_LIST_TAG_SUCCESS: null,
    GET_LIST_TAG_FAILURE: null,
});

export const {
    getListTagRequest,
    getListTagSuccess,
    getListTagFailure,
} = createActions(
    constants.GET_LIST_TAG_REQUEST,
    constants.GET_LIST_TAG_SUCCESS,
    constants.GET_LIST_TAG_FAILURE,
);
