/* eslint-disable */
/* global document: true */
import React, { Component } from 'react';
import {
    Form,
    Input,
    Button,
    Row,
    Col,
} from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'underscore';
import alertify from 'alertify.js';
import * as userActions from '../../../core/user/userActions';

const FormItem = Form.Item;

class EmailFormBox extends Component {
    emailHandleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions } = this.props;
                actions.checkUserEmailRequest({
                    email: values.email
                });
            }
        });
    };

    forgotPasswordRequest = (e) => {
        e.preventDefault();
        // eslint-disable-next-line
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailValue = document.getElementById('email');
        if (!_.isUndefined(emailValue) && emailRegex.test(emailValue.value)) {
            // do the call
            const { actions } = this.props;
            actions.forgotPasswordRequest({
                email: emailValue.value
            });
            return;
        }
        alertify.error('Please enter a valid email address.');
    }

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <div className="login-form-box">
                <Form onSubmit={this.emailHandleSubmit} layout="vertical">
                    <Row>
                        <Col xs={24}>
                            <div className="header">Your email address</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <FormItem label="Email">
                                {getFieldDecorator('email', {
                                    rules: [
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!'
                                        },
                                        {
                                            required: true,
                                            message: 'Please input your E-mail!'
                                        }
                                    ]
                                })(<Input />)}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <p><i>Please enter your school email address and not your personal address</i></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Button type="primary" htmlType="submit" className="wide">Next</Button>
                        </Col>
                    </Row>
                </Form>
                <Row style={{ marginTop: '8px' }}>
                    <Col xs={24}>
                        <a onClick={this.forgotPasswordRequest}>Forgotten password</a>
                    </Col>
                </Row>
            </div>
        );
    }
}

EmailFormBox.propTypes = {
    form: PropTypes.object.isRequired
};


const EmailForm = Form.create()(EmailFormBox);


function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailForm);
