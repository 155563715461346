/* global window:true */

import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import alertify from 'alertify.js';
import gallery from './gallery/galleryReducer';
import global from './global/globalReducer';
import user from './user/userReducer';
import comic from './comic/comicReducer';
import file from './file/fileReducer';
import tag from './tag/tagReducer';
import packageReducer from './package/packageReducer';
import company from './company/companyReducer';

alertify.logPosition('top right');
/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
    // ... your other reducers here ...
    gallery,
    global,
    user,
    comic,
    file,
    form: formReducer,
    tag,
    package: packageReducer,
    company,
});

const rootReducer = (state, action) => {
    let isSessionExpired = false;
    // ... your other reducers here ...
    if ((action.type).indexOf('_FAILURE') !== -1) {
        alertify.error(action.payload);
        // eslint-disable-next-line
        if (action.payload == 'Your request was made with invalid credentials.' || action.payload == 'not-authorised') {
            isSessionExpired = true;
        }
    }
    // eslint-disable-next-line
    if (action.type == 'LOGOUT_REQUEST' || isSessionExpired == true) {
        window.location.href = '/account/logout';
    }

    return appReducers(state, action);
};


export default rootReducer;
