import { Record } from 'immutable';

const {
    GET_LIST_PACKAGE_REQUEST,
    GET_LIST_PACKAGE_SUCCESS,
    GET_LIST_PACKAGE_FAILURE,

    GET_SHARE_PACKAGE_REQUEST,
    GET_SHARE_PACKAGE_SUCCESS,
    GET_SHARE_PACKAGE_FAILURE,

    CREATE_SHARE_PACKAGE_REQUEST,
    CREATE_SHARE_PACKAGE_SUCCESS,
    CREATE_SHARE_PACKAGE_FAILURE,
} = require('./packageActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    packages: {},
    sharePackage: {},
});

const initialState = new InitialState();

/**
* ## galleryReducer function
* @param {Object} state - initialState
* @param {Object} action - type and payload
*/
export default function packageReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_LIST_PACKAGE_REQUEST:
    case GET_SHARE_PACKAGE_REQUEST:
    case CREATE_SHARE_PACKAGE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_LIST_PACKAGE_SUCCESS:
        return state.set('isFetching', false).set('packages', payload.data);

    case GET_SHARE_PACKAGE_SUCCESS:
    case CREATE_SHARE_PACKAGE_SUCCESS:
        return state.set('isFetching', false).set('sharePackage', payload.data);

    case GET_LIST_PACKAGE_FAILURE:
    case GET_SHARE_PACKAGE_FAILURE:
    case CREATE_SHARE_PACKAGE_FAILURE:
        return state.set('isFetching', true)
            .set('error', payload);
    default:
        return state;
    }
}
