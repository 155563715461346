import React from 'react';
import Helmet from 'react-helmet';
import {
    Spin, Row, Col, Pagination, Modal, Button, Input,
} from 'antd';
import _ from 'underscore';

import Header from '../../components/Header';
import './listPackagesPage.scss';

export default function () {
    const { packages, isFetching } = this.props;
    const myProps = this.props;
    const { showLicenseKeyModal, licenseCode } = this.state;

    const {
        page, count, perPage, data,
    } = packages || {};

    let renderPackages = [];
    if (data) {
        renderPackages = data.map(obj => (
            <Col key={obj.id} xs={24} sm={24} md={12}>
                <div
                    className="resource-box"
                    style={{ cursor: 'pointer', width: '100%', height: 'inherit' }}
                    onClick={() => { this.handleClick(obj); }}>
                    <div className="image">
                        <img src={obj.imageUrl} alt={obj.name} />
                    </div>
                    <div className="title">
                        {obj.name}
                    </div>
                </div>
            </Col>
        ));
    }

    return (
        <div className="packages-page-wrap">
            <Spin spinning={isFetching}>
                <Helmet title="Packages Page" />
                <Header />
                <div className="packages-page-container">
                    <div className="display-area">
                        <Row style={{ margin: '30px auto' }}>
                            <Col xs={24}><h1>Pick a package</h1></Col>
                        </Row>
                        {data && (
                            <>
                                <Row gutter={24}>
                                    {renderPackages}
                                </Row>
                                <div className="paginationContainer">
                                    <Pagination
                                        current={page}
                                        total={count}
                                        onChange={(newPage) => { this.handlePageChange(newPage, myProps); }}
                                        pageSize={perPage}
                                        showSizeChanger={false} />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Spin>
            <Modal
                title="License Key Required"
                footer={null}
                visible={showLicenseKeyModal}
                // onOk={this.okLicenseKeyModal}
                onCancel={() => { this.setState({ showLicenseKeyModal: false }); }}>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={24} style={{ textAlign: 'center' }}>
                        <Input
                            value={licenseCode}
                            onChange={e => this.setState({ licenseCode: e.target.value })}
                            className="custom-input"
                            placeholder="Enter License Key" />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} style={{ textAlign: 'center' }}>
                        <Button
                            disabled={_.isEmpty(licenseCode)}
                            onClick={this.validateLicenceCode}
                            type="primary"
                            htmlType="button"
                            className="wide">
                            Validate
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
}
