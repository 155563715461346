import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';
import DOM from './unlockPackageForm';
import * as userActions from '../../core/user/userActions';

class UnlockPackageForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
        this.view = DOM;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { form, actions } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { accessCode } = values;
                actions.verifySharePackageRequest({ accessCode });
            } else {
                console.log('err', err);
            }
        });
    };

    render() {
        return this.view();
    }
}

UnlockPackageForm.defaultProps = {
    actions: {},
};

UnlockPackageForm.propTypes = {
    form: PropTypes.object.isRequired,
    actions: PropTypes.object,
};

const myForm = Form.create()(UnlockPackageForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.user.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(myForm);
