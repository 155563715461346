import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    SET_FORM_STEP: null,
    FLUSH_LOGIN_PROCESS: null,

    DELETE_TOKEN_REQUEST: null,
    DELETE_TOKEN_SUCCESS: null,

    SAVE_TOKEN_REQUEST: null,
    SAVE_TOKEN_SUCCESS: null,

    LOGIN_REQUEST: null,
    LOGIN_SUCCESS: null,
    LOGIN_FAILURE: null,

    VERIFY_SHARE_PACKAGE_REQUEST: null,
    VERIFY_SHARE_PACKAGE_SUCCESS: null,
    VERIFY_SHARE_PACKAGE_FAILURE: null,

    LOGOUT_REQUEST: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_FAILURE: null,

    CHECK_USER_EMAIL_REQUEST: null,
    CHECK_USER_EMAIL_SUCCESS: null,
    CHECK_USER_EMAIL_FAILURE: null,

    RESEND_VERIFICATION_EMAIL_REQUEST: null,
    RESEND_VERIFICATION_EMAIL_SUCCESS: null,
    RESEND_VERIFICATION_EMAIL_FAILURE: null,

    SEND_AUTO_LOGIN_REQUEST: null,
    SEND_AUTO_LOGIN_SUCCESS: null,
    SEND_AUTO_LOGIN_FAILURE: null,

    DO_AUTO_LOGIN_REQUEST: null,
    DO_AUTO_LOGIN_SUCCESS: null,
    DO_AUTO_LOGIN_FAILURE: null,

    FORGOT_PASSWORD_REQUEST: null,
    FORGOT_PASSWORD_SUCCESS: null,
    FORGOT_PASSWORD_FAILURE: null,

    FIRST_TIME_LOGIN_REQUEST: null,
    FIRST_TIME_LOGIN_SUCCESS: null,
    FIRST_TIME_LOGIN_FAILURE: null,

    CHANGE_PASSWORD_REQUEST: null,
    CHANGE_PASSWORD_SUCCESS: null,
    CHANGE_PASSWORD_FAILURE: null,


});

export const {
    setFormStep,
    flushLoginProcess,
} = createActions(
    constants.SET_FORM_STEP,
    constants.FLUSH_LOGIN_PROCESS,
);


export const {
    deleteTokenRequest,
    deleteTokenSuccess,
} = createActions(
    constants.DELETE_TOKEN_REQUEST,
    constants.DELETE_TOKEN_SUCCESS,
);

export const {
    saveTokenRequest,
    saveTokenSuccess,
} = createActions(
    constants.SAVE_TOKEN_REQUEST,
    constants.SAVE_TOKEN_SUCCESS,
);

export const {
    loginRequest,
    loginSuccess,
    loginFailure,
} = createActions(
    constants.LOGIN_REQUEST,
    constants.LOGIN_SUCCESS,
    constants.LOGIN_FAILURE,
);

export const {
    verifySharePackageRequest,
    verifySharePackageSuccess,
    verifySharePackageFailure,
} = createActions(
    constants.VERIFY_SHARE_PACKAGE_REQUEST,
    constants.VERIFY_SHARE_PACKAGE_SUCCESS,
    constants.VERIFY_SHARE_PACKAGE_FAILURE,
);

export const {
    logoutRequest,
    logoutSuccess,
    logoutFailure,
} = createActions(
    constants.LOGOUT_REQUEST,
    constants.LOGOUT_SUCCESS,
    constants.LOGOUT_FAILURE,
);

export const {
    checkUserEmailRequest,
    checkUserEmailSuccess,
    checkUserEmailFailure,
} = createActions(
    constants.CHECK_USER_EMAIL_REQUEST,
    constants.CHECK_USER_EMAIL_SUCCESS,
    constants.CHECK_USER_EMAIL_FAILURE,
);

export const {
    resendVerificationEmailRequest,
    resendVerificationEmailSuccess,
    resendVerificationEmailFailure,
} = createActions(
    constants.RESEND_VERIFICATION_EMAIL_REQUEST,
    constants.RESEND_VERIFICATION_EMAIL_SUCCESS,
    constants.RESEND_VERIFICATION_EMAIL_FAILURE,
);

export const {
    doAutoLoginRequest,
    doAutoLoginSuccess,
    doAutoLoginFailure,
} = createActions(
    constants.DO_AUTO_LOGIN_REQUEST,
    constants.DO_AUTO_LOGIN_SUCCESS,
    constants.DO_AUTO_LOGIN_FAILURE,
);

export const {
    sendAutoLoginRequest,
    sendAutoLoginSuccess,
    sendAutoLoginFailure,
} = createActions(
    constants.SEND_AUTO_LOGIN_REQUEST,
    constants.SEND_AUTO_LOGIN_SUCCESS,
    constants.SEND_AUTO_LOGIN_FAILURE,
);

export const {
    forgotPasswordRequest,
    forgotPasswordSuccess,
    forgotPasswordFailure,
} = createActions(
    constants.FORGOT_PASSWORD_REQUEST,
    constants.FORGOT_PASSWORD_SUCCESS,
    constants.FORGOT_PASSWORD_FAILURE,
);

export const {
    firstTimeLoginRequest,
    firstTimeLoginSuccess,
    firstTimeLoginFailure,
} = createActions(
    constants.FIRST_TIME_LOGIN_REQUEST,
    constants.FIRST_TIME_LOGIN_SUCCESS,
    constants.FIRST_TIME_LOGIN_FAILURE,
);

export const {
    changePasswordRequest,
    changePasswordSuccess,
    changePasswordFailure,
} = createActions(
    constants.CHANGE_PASSWORD_REQUEST,
    constants.CHANGE_PASSWORD_SUCCESS,
    constants.CHANGE_PASSWORD_FAILURE,
);
