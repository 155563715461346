import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_LIST_PACKAGE_REQUEST: null,
    GET_LIST_PACKAGE_SUCCESS: null,
    GET_LIST_PACKAGE_FAILURE: null,

    GET_SHARE_PACKAGE_REQUEST: null,
    GET_SHARE_PACKAGE_SUCCESS: null,
    GET_SHARE_PACKAGE_FAILURE: null,

    CREATE_SHARE_PACKAGE_REQUEST: null,
    CREATE_SHARE_PACKAGE_SUCCESS: null,
    CREATE_SHARE_PACKAGE_FAILURE: null,
});


export const {
    getListPackageRequest,
    getListPackageSuccess,
    getListPackageFailure,
} = createActions(
    constants.GET_LIST_PACKAGE_REQUEST,
    constants.GET_LIST_PACKAGE_SUCCESS,
    constants.GET_LIST_PACKAGE_FAILURE,
);

export const {
    getSharePackageRequest,
    getSharePackageSuccess,
    getSharePackageFailure,
} = createActions(
    constants.GET_SHARE_PACKAGE_REQUEST,
    constants.GET_SHARE_PACKAGE_SUCCESS,
    constants.GET_SHARE_PACKAGE_FAILURE,
);

export const {
    createSharePackageRequest,
    createSharePackageSuccess,
    createSharePackageFailure,
} = createActions(
    constants.CREATE_SHARE_PACKAGE_REQUEST,
    constants.CREATE_SHARE_PACKAGE_SUCCESS,
    constants.CREATE_SHARE_PACKAGE_FAILURE,
);
