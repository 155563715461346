/* eslint-disable react/no-this-in-sfc */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Helmet from 'react-helmet';
import {
    Row, Col, Spin, Select, Tag, Button, Pagination,
} from 'antd';

import Header from '../../components/Header';
import './fileStoragePage.scss';

const { Option } = Select;

function getIcon(fileName) {
    const iconName = fileName.split('.').pop();
    try {
        // eslint-disable-next-line global-require, import/no-dynamic-require
        return require(`../../assets/icons/${iconName}.png`);
    } catch (e) {
        // eslint-disable-next-line global-require
        const abc = require('../../assets/icons/default.png');
        return abc;
    }
}

export default function () {
    const { files, fetching, tags } = this.props;
    const {
        page, count, perPage, data,
    } = files;
    const { selectedTags } = this.state;

    const renderFiles = data ? data.map(file => (
        <Col key={file.id} xs={24} sm={24} md={12}>
            <a
                target="_blank"
                onClick={(e) => {
                    e.preventDefault();
                    this.handleFileClick(file);
                }}
                rel="noopener noreferrer"
                href={file.link}
                title={file.filename}>
                <div
                    className="file-storage-box"
                    style={{ width: '100%', height: 'inherit' }}>
                    <div className="image">
                        <img src={getIcon(file.filename)} alt={file.filename} />
                    </div>
                    <div className="title">
                        {file.title}
                    </div>
                </div>
            </a>
            <div className="tagContainer">
                {file.tags.map(tag => (
                    <Tag color="green" key={`tag-${tag}-${file.id}`}>{tag}</Tag>
                ))}
            </div>
        </Col>
    )) : null;


    return (
        <div className="file-storage-page-wrap">
            <Spin spinning={fetching}>
                <Helmet title="File Storage Page" />
                <Header />
                <div className="file-storage-page-container">
                    <div className="display-area">
                        <Row style={{ margin: '30px auto' }}>
                            <Col xs={24}><h1>Available files</h1></Col>
                        </Row>
                        <Row className="filtersRow">
                            <Select
                                mode="multiple"
                                placeholder="Search by tag"
                                onChange={this.tagsChanged}
                                style={{ width: '60%' }}
                                value={selectedTags}>
                                {tags.map(tag => <Option key={tag.id} value={tag.name}>{tag.name}</Option>)}
                            </Select>
                            <Button
                                className="searchBtn"
                                icon="search"
                                onClick={this.requestFiles}>
                                Search
                            </Button>
                            <Button className="clearBtn" onClick={this.clearTags}>Clear</Button>
                        </Row>
                        {data && (
                            <>
                                <Row gutter={24}>
                                    {renderFiles}
                                </Row>
                                <div className="paginationContainer">
                                    <Pagination
                                        current={page}
                                        total={count}
                                        onChange={this.handlePageChange}
                                        pageSize={perPage}
                                        showSizeChanger={false} />
                                </div>
                            </>
                        )}

                    </div>
                </div>
            </Spin>
        </div>
    );
}
