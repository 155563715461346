import React from 'react';
import Helmet from 'react-helmet';
import {
    Icon,
} from 'antd';
import Header from '../../components/Header';
import './externalListResourcePage.scss';
import history from '../../core/utils/history';
import ResourceListComponent from '../../components/ResourceListComponent';

export default function () {
    const navigationButtonBack = (
        <Icon
            id="navigation-back-button"
            className="resource-page-cache-hide"
            style={{ fontSize: '28px', color: '#FFFFFF', paddingTop: '10px' }}
            type="left-circle"
            onClick={() => { history.replace('/code'); }} />
    );


    return (
        <div className="externalResourcePage-wrap">
            <Helmet title="Resource Page" />
            <Header back={navigationButtonBack} showMenu={false} />
            <ResourceListComponent />
        </div>
    );
}
