/* eslint-disable react/no-unused-prop-types */
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DOM from './externalListResourcePage';
import * as userActions from '../../core/user/userActions';
import * as comicActions from '../../core/comic/comicActions';

class ExternalListResourcePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLicenseKeyModal: false,
            licenseCode: '',
            selectedComicId: null,
            selectedPackageId: null,
            selectedCompanyPackageId: null,
            selectedComicSlug: null,
            selectedComicPageSlug: null,
            selectedComicType: null,
        };
        this.view = DOM;
    }

    componentWillMount() {
        const { actions, match } = this.props;
        const { params: { packageId } } = match;
        actions.flushLoadedScriptList();
        actions.getAllAnimationsRequest({ packageId });
        actions.getAllMagazinesRequest();
    }

    render() {
        return this.view();
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        loginUser: state.user.loginUser,
        packages: state.package.packages,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...comicActions,
            ...userActions,
        }, dispatch),
    };
}

ExternalListResourcePage.defaultProps = {
    actions: {},
    match: {},
};

ExternalListResourcePage.propTypes = {
    actions: PropTypes.object,
    match: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalListResourcePage);
