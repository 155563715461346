
import React, { Component } from 'react';
import {
    Button,
    Row,
    Col,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import history from '../../../core/utils/history';
import * as userActions from '../../../core/user/userActions';


class UserNotFound extends Component {
    goBack = () => {
        const { actions } = this.props;
        actions.flushLoginProcess();
        history.push('/login');
    }

    render() {
        return (
            <div className="login-form-box">
                <Row>
                    <Col xs={24}>
                        <div className="header">User record not found</div>
                    </Col>
                </Row>
                <Row style={{ marginTop: '8px' }}>
                    <Col xs={24}>
                        <p>Unable to find user&apos;s record. Please contact administrator.</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} style={{ textAlign: 'center' }}>
                        <Button type="primary" htmlType="button" className="wide" onClick={this.goBack}>Go back to login screen</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

UserNotFound.defaultProps = {
    actions: {},
};

UserNotFound.propTypes = {
    actions: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        error: state.user.error,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserNotFound);
