import React from 'react';
import Helmet from 'react-helmet';
import {
    Row, Col, Button, Spin, Input, Form,
} from 'antd';
import Header from '../../components/Header';

export default function () {
    const { isFetching, form } = this.props;
    const { getFieldDecorator } = form;

    return (
        <div className="resource-page-wrap">
            <Spin spinning={isFetching}>
                <Helmet title="Resource Page" />
                <Header />
                <div className="loginform-container">
                    <div className="display-area">
                        <div className="login-form-box" style={{ marginTop: '100px' }}>
                            <Form onSubmit={this.setNewPasswordSubmit} layout="vertical">
                                <Row>
                                    <Col xs={24}>
                                        <div className="header">Set new password</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24}>
                                        <Form.Item label="New password">
                                            {getFieldDecorator('password', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please enter a password',
                                                    },
                                                    {
                                                        validator: this.validateToNextPassword,
                                                    },
                                                ],
                                            })(<Input type="password" />)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24}>
                                        <Form.Item label="Confirm new password">
                                            {getFieldDecorator('confirm_password', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please enter a confirm password',
                                                    },
                                                    {
                                                        validator: this.compareToFirstPassword,
                                                    },
                                                ],
                                            })(<Input type="password" onBlur={this.handleConfirmBlur} />)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '40px' }}>
                                    <Col xs={24}>
                                        <Button type="primary" htmlType="submit" className="wide">Save</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    );
}
