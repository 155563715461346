import React from 'react';
import './FormattedText.scss';

export default function (content) {
    let displayContent = content;
    if (content.children === '' || content.children) {
        displayContent = content.children;
    }

    // eslint-disable-next-line react/no-danger
    return <div className="formatted-text-wrapper" dangerouslySetInnerHTML={{ __html: displayContent }} />;
}
