import { Record } from 'immutable';

const {
    GET_ALL_FILES_REQUEST,
    GET_ALL_FILES_SUCCESS,
    GET_ALL_FILES_FAILURE,
} = require('./fileActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    files: {},
});

const initialState = new InitialState();

/**
 * ## fileReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function fileReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_ALL_FILES_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);


    case GET_ALL_FILES_SUCCESS:
        return state.set('isFetching', false)
            .set('files', payload.data || {});


    case GET_ALL_FILES_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);
    default:
        return state;
    }
}
