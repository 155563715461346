import {
    call,
    put,
    takeEvery,
    all,
} from 'redux-saga/effects';
import { message } from 'antd';

import { fetchApiAuth } from '../utils/api';

import {
    constants,

    getListPackageSuccess,
    getListPackageFailure,

    getSharePackageSuccess,
    getSharePackageFailure,

    createSharePackageSuccess,
    createSharePackageFailure,
} from './packageActions';

const {
    GET_LIST_PACKAGE_REQUEST,
    GET_SHARE_PACKAGE_REQUEST,
    CREATE_SHARE_PACKAGE_REQUEST,
} = constants;

function* getListPackage({ payload }) {
    const newPayload = {
        ...payload,
        showStandard: 0,
    };
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/package/list',
            params: newPayload,
        });

        yield put(getListPackageSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getListPackageFailure(e.response ? e.response.data.message : e));
    }
}

function* getSharePackage({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/package/get-share-package',
            params: payload,
        });

        yield put(getSharePackageSuccess(response?.data || {}));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getSharePackageFailure(e.response ? e.response.data.message : e));
    }
}

function* createSharePackage({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/package/create-share-package',
            body: payload,
        });

        yield put(createSharePackageSuccess(response?.data || {}));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(createSharePackageFailure(e.response ? e.response.data.message : e));
    }
}

export default function* ListPackageSaga() {
    yield all([
        takeEvery(GET_LIST_PACKAGE_REQUEST, getListPackage),
        takeEvery(GET_SHARE_PACKAGE_REQUEST, getSharePackage),
        takeEvery(CREATE_SHARE_PACKAGE_REQUEST, createSharePackage),
    ]);
}
