/* global window: true */
/* global document: true */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    each, filter, isNull, isUndefined,
} from 'underscore';
import DOM from './resourcePageCache';
import * as userActions from '../../core/user/userActions';
import * as comicActions from '../../core/comic/comicActions';

let animationsCount = 0;
let isWindowLoadedCalled = false;
let navigationStack = [];
let navigationPointer = -1;

class ResourcePageCache extends Component {
    constructor(props) {
        super(props);
        this.vimeoRefs = [];
        this.state = {
            selectedComic: null,
            selectedPage: null,
            initialPage: null,
            vimeoPlayer: false,
            selectedVideoUrl: '',
            videoEnded: false,
            playing: true,
        };
        navigationStack = [];
        this.view = DOM;
        window.triggerGotoAction = this.triggerGotoAction;
        window.windowResize = this.windowResize;
        window.removeFullScreen = this.removeFullScreen;
        window.addEventListener('resize', this.windowResize, false);
        document.addEventListener('keydown', this.removeFullScreen, false);
        window.onload = this.windowLoadedStartAnimation;
        window.checkAllChildrenMounted = this.checkAllChildrenMounted;
        isWindowLoadedCalled = false;
        animationsCount = 0;
        this.Loading = false;
    }


    componentWillMount() {
        const { match, animations } = this.props;
        const { params } = match;
        this.selectComicBySlugs(animations, params);
    }

    componentDidMount() {
        const { selectedComic } = this.state;


        // If navigationStack is empty add all the pages
        // eslint-disable-next-line
        if (navigationStack.length == 0 && selectedComic != null) {

            selectedComic.pages.forEach((page) => {
                const currentIframe = document.getElementsByClassName('current-iframe');
                if (currentIframe && currentIframe[0]) {
                    const isFullScreen = currentIframe[0].dataset.fullscreen && currentIframe[0].dataset.fullscreen === 'yes';
                    const src = isFullScreen ? `/account/comics/${selectedComic.slug}/${page.slug}/fullscreen` : `/account/comics/${selectedComic.slug}/${page.slug}/play`;
                    navigationStack.push({ id: page.id, src });
                    navigationPointer = 0;
                }
            });
        }
        this.toggleNavigationButtons();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {
            selectedPage: oldSelectedPage,
            videoEnded: oldVideoEnded,
            vimeoPlayer: oldVimeoPlayer,
            selectedVideoUrl: oldSelectedVideoUrl,
            playing: oldPlaying,
        } = this.state;
        const {
            selectedPage, videoEnded, vimeoPlayer, selectedVideoUrl, playing,
        } = nextState;

        if (oldSelectedPage.vimeo_url_link && oldSelectedPage !== selectedPage) {
            return true;
        }
        if (videoEnded !== oldVideoEnded) {
            return true;
        }
        if (vimeoPlayer !== oldVimeoPlayer) {
            return true;
        }
        if (oldPlaying !== playing) {
            return true;
        }
        if (selectedVideoUrl !== oldSelectedVideoUrl) {
            return true;
        }

        return false;
    }

    selectComicBySlugs = (animations, params) => {
        // eslint-disable-next-line
        const selectedComic = filter(animations, animation => animation.slug == params.comic);
        // eslint-disable-next-line
        const selectedPage = selectedComic && selectedComic[0] ? filter(selectedComic[0].pages, page => page.slug == params.page) : null;
        const initialPage = selectedPage && selectedPage[0] ? selectedPage[0].slug : null;
        const vimeoPlayer = !!(selectedPage && selectedPage[0] && selectedPage[0].vimeo_url_link);
        const selectedVideoUrl = selectedPage && selectedPage[0] ? selectedPage[0].vimeo_url_link : '';
        this.setState({
            selectedComic: selectedComic && selectedComic[0],
            selectedPage: selectedPage && selectedPage[0],
            initialPage,
            vimeoPlayer,
            selectedVideoUrl,
        });
    }

    windowLoadedStartAnimation = () => {
        // eslint-disable-next-line
        if (isWindowLoadedCalled == false) {
            const currentScene = document.getElementsByClassName('current-iframe');
            // Remove the spinner manually
            const spinner = document.getElementsByClassName('ant-spin-spinning');
            if (spinner && spinner[0]) {
                const parent1 = spinner[0].parentNode;
                parent1.parentNode.removeChild(parent1);
            }
            const spinnerBlur = document.getElementsByClassName('ant-spin-container');
            if (spinnerBlur && spinnerBlur[0]) {
                spinnerBlur[0].classList.remove('ant-spin-blur');
            }
            if (currentScene && currentScene[0]) {
                currentScene[0].contentWindow.playAnimation();
            }
            isWindowLoadedCalled = true;
        }
    }

    resizeIframe = () => null

    moveNavigationState = (direction) => {
        // eslint-disable-next-line
        if (direction == 'back') {
            if (navigationStack && navigationStack[navigationPointer - 1]) {
                navigationPointer -= 1;
                this.triggerGotoAction(null, navigationStack[navigationPointer]);
            }
            // eslint-disable-next-line
        } else if (direction == 'forward') {
            if (navigationStack && navigationStack[navigationPointer + 1]) {
                navigationPointer += 1;
                this.triggerGotoAction(null, navigationStack[navigationPointer]);
            }
        }
    }

    toggleNavigationButtons = () => {
        const forwardButton = document.getElementById('navigation-forward-button');
        const backButton = document.getElementById('navigation-back-button');
        if (backButton && forwardButton) {
            if (navigationStack.length > (navigationPointer + 1)) {
                forwardButton.classList.remove('resource-page-cache-hide');
            } else {
                forwardButton.classList.add('resource-page-cache-hide');
            }
            if (navigationPointer > 0) {
                backButton.classList.remove('resource-page-cache-hide');
            } else {
                backButton.classList.add('resource-page-cache-hide');
            }
        }
    }

    triggerGotoAction = (label = null, targetFrame = null) => {
        const { selectedComic } = this.state;
        const currentIframe = document.getElementsByClassName('current-iframe');
        if (!isUndefined(currentIframe[0])) {
            const mapped = !isNull(label) ? currentIframe[0].dataset[label.toLowerCase()] : null;
            const isFullScreen = currentIframe[0].dataset.fullscreen && currentIframe[0].dataset.fullscreen === 'yes';
            const container = document.getElementById('iframe-window-container');
            // Create a new iFrame to replace the current one
            const iFrame = document.createElement('iframe');
            iFrame.height = '100%';
            iFrame.width = '100%';
            iFrame.style.display = 'none';
            iFrame.style.width = '100%';
            iFrame.frameBorder = 0;
            iFrame.setAttribute('comic-id', selectedComic.id);
            if (isFullScreen) {
                iFrame.setAttribute('data-fullscreen', 'yes');
            }
            if (!isNull(mapped)) {
                // Get the next animation object
                const nextAnimation = filter(selectedComic.pages, page => page.id === mapped)[0] || null;
                if (nextAnimation) {
                    this.Loading = true;
                    const src = isFullScreen ? `/account/comics/${selectedComic.slug}/${nextAnimation.slug}/play/fullscreen` : `/account/comics/${selectedComic.slug}/${nextAnimation.slug}/play`;
                    iFrame.onload = () => {
                        iFrame.classList.add('current-iframe');
                        iFrame.style.display = 'block';
                        currentIframe[0].remove();
                        navigationStack.push({ id: nextAnimation.id, src });
                        navigationPointer += 1;
                        this.toggleNavigationButtons();
                        if (isFullScreen) {
                            this.fullscreenWindow();
                        }
                        this.Loading = false;
                    };
                    iFrame.id = `iframe-page-${nextAnimation.id}`;
                    iFrame.setAttribute('data-src', src);
                    iFrame.src = src;
                    each(nextAnimation.animation.actions, (userAction) => {
                        iFrame.setAttribute(`data-${userAction.label}`, userAction.mapped_page_id);
                    });
                }
            } else if (!isNull(targetFrame)) {
                let targetSrc = targetFrame.src;
                if (isFullScreen && targetSrc.indexOf('/fullscreen') === -1) {
                    targetSrc += '/fullscreen';
                } else {
                    targetSrc = targetSrc.replace('/fullscreen', '');
                }
                const filterNextAnimation = filter(selectedComic.pages, page => page.id === targetFrame.id)[0] || null;
                if (filterNextAnimation) {
                    iFrame.onload = () => {
                        iFrame.classList.add('current-iframe');
                        iFrame.style.display = 'block';
                        currentIframe[0].remove();
                        this.toggleNavigationButtons();
                        if (isFullScreen) {
                            this.fullscreenWindow();
                        }
                    };
                    iFrame.id = `iframe-page-${targetFrame.id}`;
                    iFrame.setAttribute('data-src', targetSrc);
                    iFrame.src = targetSrc;
                    each(filterNextAnimation.animation.actions, (userAction) => {
                        iFrame.setAttribute(`data-${userAction.label}`, userAction.mapped_page_id);
                    });
                }
            }
            if (iFrame.src !== '' && !isNull(iFrame.src)) {
                container.appendChild(iFrame);
            }
        }
    }

    // Vimeo
    onVideoEnd = (id) => {
        const { selectedPage } = this.state;
        if (selectedPage.id === id) {
            this.setState({ videoEnded: true, playing: false });
        }
    }

    onVideoStart = () => {
        this.setState({ videoEnded: false, playing: true });
    }

    replayVideo = () => {
        const { selectedPage } = this.state;
        const ref = this.vimeoRefs[`vimeo-video-${selectedPage.id}`];
        if (ref) {
            ref.seekTo(0);
            this.onVideoStart();
        }
    }

    createPlayerRef = (ref, key) => {
        if (key && ref !== null) {
            this.vimeoRefs[key] = ref;
        }
    }

    renderVideo = (item, props) => {
        const { isSelected } = props;
        const { selectedComic } = this.state;
        if (isSelected) {
            const { props: { url } } = item;

            const selectedPage = selectedComic.pages.find(page => page.vimeo_url_link === url);
            this.setState({ selectedVideoUrl: url, selectedPage });
        }
        return (
            <item.type
                ref={ref => this.createPlayerRef(ref, item.key)}
                {...item.props}
                {...props} />
        );
    }

    /**
     * @deprecated
     * Reason: Load next animation when the button is clicked.
     * @param label
     * @param targetFrame
     * @param action
     */
    DEPRECATED_triggerGotoAction = (label = null, targetFrame = null, action = null) => {
        const currentIframe = document.getElementsByClassName('current-iframe');
        if (!isUndefined(currentIframe[0])) {
            const mapped = !isNull(label) ? currentIframe[0].dataset[label.toLowerCase()] : null;
            currentIframe[0].style.display = 'none';
            currentIframe[0].classList.remove('current-iframe');
            const targetId = isNull(targetFrame) ? `iframe-page-${mapped}` : targetFrame;
            const target = document.getElementById(targetId);
            // eslint-disable-next-line
            if (isNull(targetFrame) && isNull(action) && navigationStack.length == (navigationPointer + 1)) {
                navigationStack.push(targetId);
                navigationPointer += 1;
            } else if (isNull(targetFrame) && isNull(action) && isNull(targetFrame)) {
                navigationStack = navigationStack.slice(0, (navigationPointer + 1));
                navigationPointer += 1;
            } else if (!isNull(targetFrame) && !isNull(action)) {
                if (action === 'back') {
                    navigationPointer -= 1;
                } else {
                    navigationPointer += 1;
                }
            }
            if (!isUndefined(target) && !isNull(target) && target.length > 0) {
                target.classList.add('current-iframe');
                target.style.display = 'block';
                this.toggleNavigationButtons();
                target.contentWindow.replayAnimation();
            }
        }
    }

    goToFullScreen = () => {
        this.fullscreenWindow();
    }

    fullscreenWindow = () => {
        const canvasContainer = document.getElementById('iframe-window-container');
        if (canvasContainer) {
            const exitButton = document.getElementsByClassName('exit-fullscreen-button');
            if (exitButton) {
                exitButton[0].style.display = 'block';
            }
            const windowWidth = (document.getElementById('app').clientWidth);
            const windowHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
            canvasContainer.style.position = 'absolute';
            canvasContainer.style.top = '0';
            canvasContainer.style.left = '12px';
            canvasContainer.style.right = '0';
            canvasContainer.style.bottom = '0';
            canvasContainer.style.height = `${windowHeight}px`;
            if (windowWidth > windowHeight) {
                canvasContainer.style.width = `${windowWidth}px`;
            }

            const iframeDocs = canvasContainer.getElementsByTagName('iframe');
            each(iframeDocs, (iframeDoc) => {
                const tempIframeDoc = iframeDoc;
                tempIframeDoc.style.height = '99vh';
                tempIframeDoc.setAttribute('data-fullscreen', 'yes');
                const localDoc = iframeDoc.contentWindow.document;

                if (localDoc) {
                    const canvasLocal = localDoc.getElementById('animation-canvas');
                    if (canvasLocal) {
                        setTimeout(() => {
                            const canvas = canvasLocal.firstChild;
                            canvas.height = windowHeight;
                            canvas.width = windowWidth;
                            canvas.style.width = `${windowWidth}px`;
                            canvas.style.height = `${windowHeight}px`;
                            canvasLocal.style.position = 'absolute';
                            canvasLocal.style.top = '0';
                            canvasLocal.style.left = '0';
                            canvasLocal.style.width = `${windowWidth}px`;
                            canvasLocal.style.height = `${windowHeight}px`;
                            canvasLocal.width = '100%';
                            canvasLocal.height = '100%';
                        }, 100);
                    }
                }
            });
        }
        setTimeout(() => {
            const currentIframe = document.getElementsByClassName('current-iframe');
            if (currentIframe && currentIframe[0]) {
                currentIframe[0].style.height = '100vh';
            }
        }, 300);
    }

    removeFullScreen = (e) => {
        const keyCode = e.which || e.keyCode;
        // eslint-disable-next-line
        if (keyCode == 27) {
            this.exitFullScreen();
        }
    }

    exitFullScreen = () => {
        const canvasContainer = document.getElementById('iframe-window-container');
        if (canvasContainer) {
            const exitButton = document.getElementsByClassName('exit-fullscreen-button');
            if (exitButton) {
                exitButton[0].style.display = 'none';
            }
            canvasContainer.style.position = 'relative';
            canvasContainer.style.height = '80vh';
            canvasContainer.style.width = '100%';
            const iframeDocs = canvasContainer.getElementsByTagName('iframe');
            each(iframeDocs, (iframeDoc) => {
                const tempIframeDoc = iframeDoc;
                tempIframeDoc.style.height = '100%';
                tempIframeDoc.removeAttribute('data-fullscreen');
                const localDoc = iframeDoc.contentWindow.document;
                if (localDoc) {
                    const canvasLocal = localDoc.getElementById('animation-canvas');
                    if (canvasLocal) {
                        canvasLocal.style.position = 'relative';
                        canvasLocal.style.width = '100%';
                        canvasLocal.style.height = '100%';
                        const canvas = canvasLocal.firstChild;
                        canvas.height = 1080;
                        canvas.width = 1920;
                        canvas.style.width = '100%';
                        canvas.style.height = '100%';
                    }
                }
            });
        }
    }

    checkAllChildrenMounted = () => {
        animationsCount -= 1;
        // eslint-disable-next-line
        if (animationsCount == 0) {
            this.windowLoadedStartAnimation();
        }
    }

    windowResize = () => {
        const exitButton = document.getElementsByClassName('exit-fullscreen-button');
        if (exitButton && exitButton[0] && exitButton[0].style.display === 'block') {
            this.fullscreenWindow();
        }
    }

    onReceiveMessage = () => null;

    render() {
        return this.view();
    }
}

ResourcePageCache.defaultProps = {
    params: {},
};
ResourcePageCache.propTypes = {
    match: PropTypes.object.isRequired,
    animations: PropTypes.array.isRequired,
    params: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.comic.isFetching,
        animations: state.comic.animations,
        loginUser: state.user.loginUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...comicActions,
            ...userActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourcePageCache);
