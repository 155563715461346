/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Popover } from 'antd';
import ReactPlayer from 'react-player/lazy';
import { YoutubeOutlined, FilePdfOutlined, InfoCircleOutlined } from '@ant-design/icons';
import './Materials.scss';


class Materials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            videoLink: '',
        };
    }

    render() {
        const { data } = this.props;
        const { modalOpen, videoLink } = this.state;
        if (data.length) {
            const items = data.map(d => (
                <div key={d.id} className="materials-item-wrapper">
                    <div className="material-name">{d.name}</div>
                    {d.info_text && d.info_text !== ''
                        ? (
                            <Popover className="info-wrapper" content={d.info_text} trigger="hover">
                                <InfoCircleOutlined style={{ fontSize: 14 }} />
                            </Popover>
                        )
                        : null
                    }
                    <div className="padding" />
                    { !d.video_url
                        ? null
                        : (
                            <div
                                onClick={() => this.setState({
                                    modalOpen: true,
                                    videoLink: d.video_url,
                                })}
                                className="material-button">
                                How to <YoutubeOutlined
                                    className="icon"
                                    style={{ fontSize: 18 }} />
                            </div>
                        )
                    }
                    {d.file_url
                        ? (
                            <a
                                href={d.file_url}
                                target="_blank"
                                rel="noreferrer"
                                className="material-button">
                                View <FilePdfOutlined
                                    className="icon"
                                    style={{ fontSize: 18 }} />
                            </a>
                        )
                        : (
                            <a
                                href="#"
                                disabled
                                className="material-button disabled">
                                View <FilePdfOutlined
                                    className="icon"
                                    style={{ fontSize: 18 }} />
                            </a>
                        )
                    }
                </div>
            ));

            return (
                <div className="materials-block-wrapper">
                    <h2>Materials</h2>
                    <div className="materials-list-wrapper">{items}</div>

                    <Modal
                        title="Video Material"
                        visible={modalOpen}
                        width={700}
                        centered
                        closable
                        footer={null}
                        onCancel={() => this.setState({ modalOpen: false })}>
                        {modalOpen
                            ? (
                                <ReactPlayer url={videoLink} />
                            )
                            : null
                        }
                    </Modal>
                </div>
            );
        }
        return null;
    }
}

Materials.defaultProps = {
    data: [],
};

Materials.propTypes = {
    data: PropTypes.array,
};


export default Materials;
