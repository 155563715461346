import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_ALL_FILES_REQUEST: null,
    GET_ALL_FILES_SUCCESS: null,
    GET_ALL_FILES_FAILURE: null,
});

export const {
    getAllFilesRequest,
    getAllFilesSuccess,
    getAllFilesFailure,
} = createActions(
    constants.GET_ALL_FILES_REQUEST,
    constants.GET_ALL_FILES_SUCCESS,
    constants.GET_ALL_FILES_FAILURE,
);
