import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'underscore';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import DOM from './listResourcePage';
import * as userActions from '../../core/user/userActions';
import * as comicActions from '../../core/comic/comicActions';
import * as packageActions from '../../core/package/packageActions';
import history from '../../core/utils/history';

class ListResourcePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLicenseKeyModal: false,
            licenseCode: '',
            selectedComicId: null,
            selectedPackageId: null,
            selectedCompanyPackageId: null,
            selectedComicSlug: null,
            selectedComicPageSlug: null,
            selectedComicType: null,
        };
        this.view = DOM;
    }


    componentWillMount() {
        const { actions, match, loginUser } = this.props;
        const { params: { packageId } } = match;
        actions.flushLoadedScriptList();
        actions.getAllAnimationsRequest({ packageId });
        actions.getAllMagazinesRequest();
        if (match && match.params) {
            if (!_.isUndefined(match.params.comic) && !_.isNull(match.params.comic)) {
                this.setState({ comicId: match.params.comic });
            }
            if (!_.isUndefined(match.params.page) && !_.isNull(match.params.page)) {
                this.setState({ pageId: match.params.page });
            }
        }

        if (loginUser.role === 'admin' || loginUser.role === 'teacher') {
            actions.getSharePackageRequest({ packageId });
        }
    }

    componentDidMount() {
        const { packages, match, loginUser } = this.props;
        const { params: { packageId } } = match;

        if (loginUser.role !== 'admin') {
            if (packages && packages.data) {
                const p = packages.data.find(pack => pack.id === packageId);
                if (!p || !p.credit_allocation_id) {
                    history.push('/account/packages');
                }
            } else {
                history.push('/account/packages');
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match !== nextProps.match) {
            const { params } = nextProps.match;
            if (!_.isUndefined(params.comic) && !_.isNull(params.comic)) {
                this.setState({ comicId: params.comic });
            }
            if (!_.isUndefined(params.page) && !_.isNull(params.page)) {
                this.setState({ pageId: params.page });
            }
        }
    }

    notAllowedComic = () => {
        Alert('Don\'t have permission');
    }

    closeLicenseKeyModal = () => {
        this.setState({
            showLicenseKeyModal: false,
            selectedComicId: null,
            selectedPackageId: null,
            selectedCompanyPackageId: null,
            selectedComicSlug: null,
            selectedComicPageSlug: null,
        });
    }

    okLicenseKeyModal = () => {
        const {
            licenseCode,
            selectedComicId,
            selectedPackageId,
            selectedCompanyPackageId,
            selectedComicSlug,
            selectedComicPageSlug,
            selectedComicType,
        } = this.state;
        const { actions, loginUser } = this.props;
        actions.userAllocateCreditRequest({
            licenseCode,
            companyId: loginUser.company_id,
            comicId: selectedComicId,
            packageId: selectedPackageId,
            companyPackageId: selectedCompanyPackageId,
            data: loginUser.email,
            selectedComicSlug,
            selectedComicPageSlug,
            selectedComicType,
        });
        this.setState({
            showLicenseKeyModal: false,
            licenseCode: '',
            selectedComicId: null,
            selectedPackageId: null,
            selectedCompanyPackageId: null,
            selectedComicSlug: null,
            selectedComicPageSlug: null,
            selectedComicType: null,
        });
    }

    handleSharePackageClick = () => {
        const { actions, match } = this.props;
        const { params: { packageId } } = match;
        actions.createSharePackageRequest({ packageId });
    }

    render() {
        return this.view();
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        loginUser: state.user.loginUser,
        packages: state.package.packages,
        sharePackage: state.package.sharePackage,
        isFetching: state.package.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...comicActions,
            ...userActions,
            ...packageActions,
        }, dispatch),
    };
}

ListResourcePage.defaultProps = {
    actions: {},
    loginUser: {},
    match: {},
    packages: {},
};

ListResourcePage.propTypes = {
    actions: PropTypes.object,
    loginUser: PropTypes.object,
    packages: PropTypes.object,
    match: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListResourcePage);
