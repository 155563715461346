import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import * as packageActions from '../../core/package/packageActions';
import * as companyActions from '../../core/company/companyActions';
import history from '../../core/utils/history';
import DOM from './listPackagesPage';

class ListResourcePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            test: false,
            showLicenseKeyModal: false,
            selectedPackageId: null,
            selectedCompanyPackageId: null,
            licenseCode: '',
        };
        this.view = DOM;
    }

    componentWillMount() {
        const { actions } = this.props;
        actions.getListPackageRequest();
    }

    componentDidUpdate(prevProps) {
        const { creditAllocating, actions } = this.props;

        if (!creditAllocating && prevProps.creditAllocating) {
            this.setState({ showLicenseKeyModal: false });
            actions.getListPackageRequest();
        }
    }


    askForPackageLicenseKey = (packageId, companyPackageId) => {
        this.setState({
            showLicenseKeyModal: true,
            selectedCompanyPackageId: companyPackageId,
            selectedPackageId: packageId,
        });
    }

    validateLicenceCode = () => {
        const { actions, loginUser } = this.props;
        const { licenseCode, selectedCompanyPackageId, selectedPackageId } = this.state;
        actions.postAllocateCreditRequest({
            licenseCode,
            packageId: selectedPackageId,
            companyPackageId: selectedCompanyPackageId,
            companyId: loginUser.company_id,
            data: loginUser.email,
        });
    }

    handleClick(item) {
        const { loginUser } = this.props;
        if (item.credit_allocation_id || parseInt(item.allocation_method, 10) !== 0 || loginUser.role === 'admin') {
            history.push(`/account/comics/${item.id}`);
        } else {
            this.askForPackageLicenseKey(item.id, item.scorch_company_package_id);
        }
    }

    handlePageChange(page, props) {
        const { actions } = props;
        actions.getListPackageRequest({ page });
    }

    render() {
        return this.view();
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        packages: state.package.packages,
        isFetching: state.package.isFetching,
        loginUser: state.user.loginUser,
        creditAllocating: state.company.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...packageActions,
            ...companyActions,
        }, dispatch),
    };
}

ListResourcePage.defaultProps = {
    actions: {},
    loginUser: {},
    creditAllocating: false,
};

ListResourcePage.propTypes = {
    actions: PropTypes.object,
    loginUser: PropTypes.object,
    creditAllocating: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListResourcePage);
