/* eslint-disable react/no-unused-prop-types */
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import DOM from './resourceListComponent';

class ResourceListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { };
        this.view = DOM;
    }

    render() {
        return this.view();
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.comic.isFetching,
        animations: state.comic.animations,
        loginUser: state.user.loginUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
        }, dispatch),
    };
}

ResourceListComponent.propTypes = {
    askForComicLicenseKey: PropTypes.func,
    autoAssignComic: PropTypes.func,
    notAllowedComic: PropTypes.func,
};

ResourceListComponent.defaultProps = {
    askForComicLicenseKey: () => {},
    autoAssignComic: () => {},
    notAllowedComic: () => {},
};


export default connect(mapStateToProps, mapDispatchToProps)(ResourceListComponent);
