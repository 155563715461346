/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Drawer } from 'antd';
import './header.css';
import LogoMain from '../../assets/images/logo-new.png';
import Logo from '../../assets/images/lime_logo_leaf.png';
import ResourceSelectIcon from '../../assets/images/resource-select-icon.png';
import LockIcon from '../../assets/images/lock-icon.png';
import LogoutIcon from '../../assets/images/logout-icon.png';

export default function () {
    const { loginUser, refreshButton, replayButton, forward, back, showMenu } = this.props;
    const drawerTitle = (
        <div>
            <Row>
                <Col xs={24} style={{ textAlign: 'center' }}>
                    <img alt="Engage Lime Portal" src={Logo} width="106px" />
                </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
                <Col xs={24} style={{ textAlign: 'center' }}>
                    <h4>You're currently logged in as:</h4>
                </Col>
            </Row>
            <Row>
                <Col xs={24} style={{ textAlign: 'center' }}>
                    <a>{loginUser && loginUser.username ? loginUser.username : null}</a>
                </Col>
            </Row>
        </div>
    );

    return (
        <div>
            <div className="top-bar">
                <div className="resource-page-container">
                    {showMenu && (
                        <Col xs={2} style={{ padding: '10px 0px' }}>
                            <a onClick={() => this.setState({ drawerVisible: true })}>
                                <div className="menu-icon" />
                            </a>
                        </Col>
                    )}
                    <Col xs={1}>{back}</Col>
                    <Col xs={18}>
                        <div className="title">
                            <img alt="Engage Lime Portal" src={Logo} width="80px" />
                            Learning portal
                        </div>
                    </Col>
                    <Col xs={1}>{forward}</Col>
                    <Col xs={2} style={{ padding: '9px 0px' }}>
                        {replayButton}
                    </Col>
                    <Col xs={2} style={{ padding: '10px 0px' }}>
                        {refreshButton}
                    </Col>
                </div>
            </div>
            <Drawer
                title={drawerTitle}
                width="300px"
                placement="left"
                onClose={this.onDrawerClose}
                visible={this.state.drawerVisible}>
                <div className="drawer-link-item">
                    <Link to="/account/packages" onClick={() => this.setState({ drawerVisible: false })}>
                        <img alt="" className="icon" src={ResourceSelectIcon} />
                        Package select
                    </Link>
                </div>
                <div className="drawer-link-item">
                    <Link to="/account/files" onClick={() => this.setState({ drawerVisible: false })}>
                        <img alt="" className="icon" src={ResourceSelectIcon} />
                        File storage
                    </Link>
                </div>                
                <div className="drawer-link-item">
                    <Link to="/account/settings" onClick={() => this.setState({ drawerVisible: false })}>
                        <img alt="" className="icon" src={LockIcon} />
                        Change password
                    </Link>
                </div>
                <div className="drawer-link-item">
                    <Link to="/account/logout" onClick={this.doLogoutRequest}>
                        <img alt="" className="icon" src={LogoutIcon} />
                        Logout
                    </Link>
                </div>
            </Drawer>
        </div>
    );
}
