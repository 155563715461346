import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_ALL_ANIMATIONS_REQUEST: null,
    GET_ALL_ANIMATIONS_SUCCESS: null,
    GET_ALL_ANIMATIONS_FAILURE: null,

    GET_ANIMATIONS_REQUEST: null,
    GET_ANIMATIONS_SUCCESS: null,
    GET_ANIMATIONS_FAILURE: null,

    GET_ALL_MAGAZINES_REQUEST: null,
    GET_ALL_MAGAZINES_SUCCESS: null,
    GET_ALL_MAGAZINES_FAILURE: null,

    MARK_SESSION_AS_DONE_REQUEST: null,
    MARK_SESSION_AS_DONE_SUCCESS: null,
    MARK_SESSION_AS_DONE_FAILURE: null,

    UPDATE_LOADED_SCRIPT_LIST: null,
    FLUSH_LOADED_SCRIPT_LIST: null,
});

export const {
    getAllAnimationsRequest,
    getAllAnimationsSuccess,
    getAllAnimationsFailure,
} = createActions(
    constants.GET_ALL_ANIMATIONS_REQUEST,
    constants.GET_ALL_ANIMATIONS_SUCCESS,
    constants.GET_ALL_ANIMATIONS_FAILURE,
);

export const {
    getAnimationsRequest,
    getAnimationsSuccess,
    getAnimationsFailure,
} = createActions(
    constants.GET_ANIMATIONS_REQUEST,
    constants.GET_ANIMATIONS_SUCCESS,
    constants.GET_ANIMATIONS_FAILURE,
);

export const {
    getAllMagazinesRequest,
    getAllMagazinesSuccess,
    getAllMagazinesFailure,
} = createActions(
    constants.GET_ALL_MAGAZINES_REQUEST,
    constants.GET_ALL_MAGAZINES_SUCCESS,
    constants.GET_ALL_MAGAZINES_FAILURE,
);

export const {
    markSessionAsDoneRequest,
    markSessionAsDoneSuccess,
    markSessionAsDoneFailure,
} = createActions(
    constants.MARK_SESSION_AS_DONE_REQUEST,
    constants.MARK_SESSION_AS_DONE_SUCCESS,
    constants.MARK_SESSION_AS_DONE_FAILURE,
);

export const {
    updateLoadedScriptList,
    flushLoadedScriptList,
} = createActions(
    constants.UPDATE_LOADED_SCRIPT_LIST,
    constants.FLUSH_LOADED_SCRIPT_LIST,
);
