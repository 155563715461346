/* global window: true */
/* global document: true */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    filter, isUndefined, find,
} from 'underscore';
import DOM from './resourcePage';
// import history from '../../core/utils/history';
import * as userActions from '../../core/user/userActions';
import * as comicActions from '../../core/comic/comicActions';
import * as globalActions from '../../core/global/globalActions';

class ResourcePage extends Component {
    constructor(props) {
        super(props);
        this.adobeObject = React.createRef();
        this.state = {
            selectedComic: null,
            selectedPage: null,
            playStatus: true,
            initialPage: null,
            scriptsLoaded: [],
            scriptLoaded: false,
            animationObject: null,
        };
        window.triggerGotoAction = this.triggerGotoAction;
        window.playAnimation = this.playAnimation;
        window.replayAnimation = this.replayAnimation;
        document.addEventListener('keydown', this.removeFullScreen, false);
        window.addEventListener('resize', this.windowResize, false);
        window.onload = () => {
            window.parent.checkAllChildrenMounted();
        };
        this.view = DOM;
    }

    componentWillMount() {
        const { match, animations } = this.props;
        const { params } = match;
        this.selectComicBySlugs(animations, params);
    }

    componentDidMount() {
        const { match } = this.props;
        const { params } = match;

        if (params.playStatus && params.playStatus === 'play') {
            this.setState({ playStatus: false });
        }
    }

    componentWillReceiveProps() {
        const { match, animations } = this.props;
        const { params } = match;
        this.selectComicBySlugs(animations, params);
    }

    compareObjects = (o1, o2) => {
        /* eslint-disable no-restricted-syntax */
        for (const p in o1) {
            // eslint-disable-next-line no-prototype-builtins
            if (o1.hasOwnProperty(p)) {
                if (o1[p] !== o2[p]) {
                    return false;
                }
            }
        }
        for (const p1 in o2) {
            // eslint-disable-next-line no-prototype-builtins
            if (o2.hasOwnProperty(p1)) {
                if (o1[p1] !== o2[p1]) {
                    return false;
                }
            }
        }
        return true;
        /* eslint-enable no-restricted-syntax */
    }

    selectComicBySlugs = (animations, params) => {
        // eslint-disable-next-line
        const selectedComic = filter(animations, animation => animation.slug == params.comic);
        // eslint-disable-next-line
        const selectedPage = selectedComic && selectedComic[0] ? filter(selectedComic[0].pages, page => page.slug == params.page) : null;
        const initialPage = selectedPage && selectedPage[0] ? selectedPage[0].slug : null;
        this.setState({
            selectedComic: selectedComic && selectedComic[0],
            selectedPage: selectedPage && selectedPage[0],
            initialPage,
        });
    }

    removeFullScreen = (e) => {
        window.parent.removeFullScreen(e);
    }

    getAnimationObject = (obj) => {
        this.setState({ animationObject: obj });
    }

    playAnimation = () => {
        const { animationObject } = this.state;
        animationObject.tickEnabled = true;
        this.setState({ animationObject });
    }

    replayAnimation = () => {
        const { animationObject } = this.state;
        animationObject.tickEnabled = true;
        this.setState({ animationObject });
        animationObject.gotoAndPlay(0);
    }

    handleScriptCreate = (animationName) => {
        const { actions, scriptsLoaded } = this.props;
        // eslint-disable-next-line
        const found = find(scriptsLoaded, item => item.key == animationName);
        if (isUndefined(found)) {
            actions.updateLoadedScriptList({ key: animationName, value: 1 });
        }
    }

    handleScriptError = (animationName) => {
        const { actions } = this.props;
        actions.updateLoadedScriptList({ key: animationName, value: 3 });
    }

    handleScriptLoad = (animationName) => {
        const { actions } = this.props;
        actions.updateLoadedScriptList({ key: animationName, value: 2 });
    }

    triggerGotoAction = (label) => {
        window.parent.triggerGotoAction(label);
    }

    handleScriptCreateSingle = () => { this.setState({ scriptLoaded: false }); }

    handleScriptErrorSingle = () => { this.setState({ scriptLoaded: false }); }

    handleScriptLoadSingle = () => { this.setState({ scriptLoaded: true }); }

    render() {
        return this.view();
    }
}

ResourcePage.defaultProps = {
    match: null,
    scriptsLoaded: false,
    animations: [],
};

ResourcePage.propTypes = {
    actions: PropTypes.object.isRequired,
    match: PropTypes.object,
    scriptsLoaded: PropTypes.bool,
    animations: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.comic.isFetching,
        animations: state.comic.animations,
        isComicFullscreen: state.global.isComicFullscreen,
        scriptsLoaded: state.comic.scriptsLoaded,
        comic: state.comic,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...comicActions,
            ...userActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourcePage);
