// APP CONSTATNS

const {
    REACT_APP_ENV,

    REACT_APP_URL,
    REACT_APP_API_URL,
} = process.env;

const app = {
    ENV: REACT_APP_ENV,
    VERSION: '1.0.0',
    DEBUG: REACT_APP_ENV === 'local',
    MOCK: false,
    APP_NAME: 'Engage Lime Portal',
    APP_URL: REACT_APP_URL || '',
    API_URL: REACT_APP_API_URL || '',
    STACKABLE_KEY: 'bBAtfmcHmgAz',
    GALLERY_CONTAINER: '2n6Lbqw8oAzpQB5Zt',
    AUTH_KEY: 'lime-auth-key',
    AUTH_ROLE: 'lime-auth-role',
    LOCALSTORAGE_EXPIRES_KEY: 'lime-expire-key',
    LOCALSTORAGE_EXPIRES: '30', // minutes
};

export default app;
