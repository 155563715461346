import { Record } from 'immutable';
import { find, isUndefined, indexOf } from 'underscore';

const {
    GET_ALL_ANIMATIONS_REQUEST,
    GET_ALL_ANIMATIONS_SUCCESS,
    GET_ALL_ANIMATIONS_FAILURE,

    GET_ANIMATIONS_REQUEST,
    GET_ANIMATIONS_SUCCESS,
    GET_ANIMATIONS_FAILURE,

    USER_ALLOCATE_CREDIT_REQUEST,
    USER_ALLOCATE_CREDIT_SUCCESS,
    USER_ALLOCATE_CREDIT_FAILURE,

    GET_ALL_MAGAZINES_REQUEST,
    GET_ALL_MAGAZINES_SUCCESS,
    GET_ALL_MAGAZINES_FAILURE,

    MARK_SESSION_AS_DONE_REQUEST,
    MARK_SESSION_AS_DONE_SUCCESS,
    MARK_SESSION_AS_DONE_FAILURE,

    UPDATE_LOADED_SCRIPT_LIST,
    FLUSH_LOADED_SCRIPT_LIST,

} = require('./comicActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    animations: [],
    selectedAnimation: {},
    scriptsLoaded: [],
    isMagazinesFetching: false,
    magazines: [],
});

const initialState = new InitialState();

/**
 * ## comicReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function comicReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_ALL_ANIMATIONS_REQUEST:
    case GET_ANIMATIONS_REQUEST:
    case USER_ALLOCATE_CREDIT_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);
    case GET_ALL_MAGAZINES_REQUEST:
    case MARK_SESSION_AS_DONE_REQUEST:
        return state.set('isMagazinesFetching', true)
            .set('error', null);


    case GET_ALL_ANIMATIONS_SUCCESS:
        return state.set('isFetching', false)
            .set('animations', payload.data.data);
    case USER_ALLOCATE_CREDIT_SUCCESS:
        return state.set('isFetching', false);
    case GET_ALL_MAGAZINES_SUCCESS:
    case MARK_SESSION_AS_DONE_SUCCESS:
        return state.set('isMagazinesFetching', false)
            .set('magazines', payload.data.data);
    case GET_ANIMATIONS_SUCCESS:
        return state.set('isFetching', false)
            .set('selectedAnimation', payload.data.data);


    case GET_ALL_ANIMATIONS_FAILURE:
    case GET_ANIMATIONS_FAILURE:
    case USER_ALLOCATE_CREDIT_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);
    case GET_ALL_MAGAZINES_FAILURE:
    case MARK_SESSION_AS_DONE_FAILURE:
        return state.set('isMagazinesFetching', false)
            .set('error', payload);


    case UPDATE_LOADED_SCRIPT_LIST: {
        const tempScriptsLoaded = state.scriptsLoaded;
        // eslint-disable-next-line
        const foundKey = find(tempScriptsLoaded, (item) => item.key == payload.key);
        if (isUndefined(foundKey)) {
            tempScriptsLoaded.push(payload);
        } else {
            const keyIndex = indexOf(tempScriptsLoaded, foundKey);
            tempScriptsLoaded[keyIndex].value = payload.value;
        }
        return state.set('scriptsLoaded', tempScriptsLoaded);
    }
    case FLUSH_LOADED_SCRIPT_LIST:
        return state.set('scriptsLoaded', []);
    default:
        return state;
    }
}
